.manageWrap {
  padding: 20px;
  background-color: #f9f9f9;
}

.manuscriptList {
  margin-bottom: 20px;
}

.manuscriptList h3 {
  margin-bottom: 10px;
}

.manuscriptList button {
  margin-right: 10px;
  padding: 10px 15px;
  border: none;
  background-color: #3fac8c;
  color: white;
  cursor: pointer;
  border-radius: 5px;
}

.manuscriptList button:hover {
  background-color: #358c76;
}

.manuscriptList .activeButton {
  background-color: #285f4d;
}

.responsesWrap {
  margin-top: 20px;
}

.responseItem {
  border: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: #fff;
}

.responseItem p {
  margin: 5px 0;
}

.responseActions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.responseActions label {
  display: flex;
  align-items: center;
}

.deleteButton {
  background-color: #d9534f;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
}

.deleteButton:hover {
  background-color: #c9302c;
}
