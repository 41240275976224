#sectionHeaderApp {
  width: 100%;
  height: auto;
}
.headerAppWrap {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: #f7cb25;
}
.language {
  width: calc(134px - 26px);
  height: calc(37px - 20px);
  padding: 10px 13px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
  font-family: "Ubuntu";
  border-radius: 21px;
  border: 1px solid #3e3092;
  position: relative;
  background: #fff;
}
.wrapAllContacWhaLan {
  padding: 10px 13px;
  width: calc(134px - 26px);
  height: auto;
  border-radius: 0 0 21px 21px;
  border: 1px solid #3e3092;

  background: #fff;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  top: 21px;
  z-index: 1;
  padding-top: 25px;
}
.rightGuideLink {
  color: #3e3092;

  font-family: Ubuntu;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 117.5%;
  margin: 0;
  text-decoration: none;
  margin-bottom: 10px;
}
.uaTextRet {
  margin: 0;
  color: #3e3092;

  font-family: Ubuntu;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 117.5%;
}
.textLanguage {
  width: 38px;
  height: 19px;
  color: #3e3092;

  text-align: center;
  font-family: Ubuntu;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 117.5%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  border-radius: 10px;
  background: #fff;
}
.rightGuide {
  display: flex;
  width: 97px;
  height: 19px;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background: #fff;
  color: #3e3092;

  text-align: center;
  font-family: Ubuntu;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 117.5%;
}
.iconLanguage {
  display: flex;
  flex-direction: column;
  width: calc(24px - 4px);
  height: calc(18px - 4px);
  border: 2px solid white;
}
.blue {
  width: 100%;
  height: 50%;
  background: #0057b8;
}
.yellow {
  width: 100%;
  height: 50%;
  background: #ffd700;
}
.wrapSocial {
  width: 222.45px;
  height: 33.56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.socialBlock {
  width: 32.6px;
  height: 32.01px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: white;
  cursor: pointer;
}
.socialIcon {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.wrapPinSlonPop {
  width: 100px;
  height: auto;
  text-align: start;
  color: #fff;

  font-family: Ubuntu;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 111%;
  margin: 0;
}
.wrapRelativeSlon {
  position: absolute;
  width: calc(175px - 24px);
  height: calc(92px - 24px);
  padding: 12px;
  border-radius: 21px 0px 21px 21px;
  border: 1px #3e3092;

  background: #3e3092;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  top: 40px;
  right: 20px;
  z-index: 10;
}
.counterProd {
  position: absolute;
  left: 55%;
  top: -20%;
  width: 20px;
  height: 17px;
  background: #ff3416;
  border-radius: 6px;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 117.5%;
  /* or 16px */

  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
}
.instaIcon {
  width: 19.88px;
  height: 19.29px;
  color: #3e3092;
}
.sunWrap {
  width: 543px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: "Ubuntu";
  font-style: normal;
  font-size: 13px;
  font-weight: 700;
  /* or 19px */

  display: flex;
  background-color: #3fac8c;
  -webkit-clip-path: polygon(5% 0, 95% 0, 100% 50%, 95% 100%, 5% 100%, 0 50%);
  clip-path: polygon(5% 0, 95% 0, 100% 50%, 95% 100%, 5% 100%, 0 50%);
}
.spanFast {
  color: #3e3092;
  font-size: 15px;
}
.pFast {
  font-size: 13px;
  font-weight: bold;
  color: white;
}
.numberWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 172px;
  height: 42px;
  background: white;
  border-radius: 21px;
}
.wrapConnectMenu {
  width: calc(222px - 26px);
  height: calc(42px - 20px);
  padding: 10px 13px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 21px;
  border: 1px solid #3e3092;
  cursor: pointer;
  background: #fff;
  z-index: 10; /* Підвищено значення z-index */
  position: relative; /* Задає відносне позиціонування */
}
.arrowDownHeaderContact {
  background: white;
}
.wrapAllConnectInMenuHeader {
  position: relative;
  width: auto;
  height: auto;
}
.wrapAllContacWhaTelVib {
  padding: 10px 13px;
  width: calc(222px - 26px);
  height: auto;
  border-radius: 0 0 21px 21px;
  border: 1px solid #3e3092;

  background: #fff;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  top: 21px;
  z-index: 1;
  padding-top: 25px;
}

.linkPlInHeader {
  text-decoration: none;
}
.wrapNumberWithIconCon {
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.connectToViberA {
  color: #3e3092;
  margin: 0;
  font-family: Ubuntu;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 117.5%;
  margin-left: 14px;
  text-decoration: none;
}
.mailPhotoSvg {
  width: 24px;
  height: 24px;
}
.linkViberOr {
  text-decoration: none;
  margin-top: 10px;
}
.connectPinHeader {
  margin: 0;
  color: #3e3092;

  font-family: Ubuntu;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 117.5%;
}
.phoneNumber {
  font-family: "Ubuntu";
  font-weight: 700;
  font-size: 14px;
  margin: 0;
  margin-left: 4px;
  color: #3e3092;
}
.phoneHref {
  font-size: 14px;
  color: #3e3092;
  text-decoration: none;
  font-family: "Ubuntu";
  font-weight: 700;
}
.wrapSearch {
  width: 228.33px;
  height: 28.33px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.searchBlock {
  width: 30px;
  height: 28px;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  cursor: pointer;
}
.userNameInHeader {
  color: #3e3092;

  text-align: center;
  font-family: Ubuntu;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 121%;
  margin: 0;
}
.searchBlockGrenn {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #3fac8c;

  cursor: pointer;
}

/* Блок нижній */
.headeDownWrap {
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
}
.wrapLogo {
  display: flex;
  align-items: center;
}
.logoH1 {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 120%;
  color: #3e3092;
  text-decoration: none;
}
.logoH1 span {
  font-weight: 700;
  text-decoration: none;
}
.navigationWrap {
  display: flex;
  flex-direction: column;
  height: auto;

  width: 70%;
}
.navigationUl {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 2px solid #f7cb25;
  margin: 0;
  height: 58px;
  box-sizing: border-box;
  position: relative;
  padding: 0;
}
.navigationUlFilter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 71.2%;

  margin: 0;
  height: 58px;
}
.navigationLi {
  list-style: none;
  font-family: "Ubuntu";
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  color: #3e3092;
  position: relative;
  min-width: calc(145px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.navigationUrlVisit {
  list-style: none;
  font-family: "Ubuntu";
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  color: #3e3092;
  position: relative;
  background: #f7cb25;
  padding-left: 10px;
  padding-right: 10px;
  min-width: calc(145px - 20px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.navigationLiHover {
  list-style: none;
  font-family: "Ubuntu";
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  color: #3e3092;
  position: relative;
  min-width: calc(145px);
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
.filterButton .active {
  width: 207px;
  height: 49px;
  background: #f7cb25;
}
.optLinkHeader {
  height: 100%;
  text-decoration: none;
  margin-top: 20px;
  margin-bottom: 20px;
}
.filterButton {
  position: absolute;
  top: 62px; /* Розміщуємо кнопку точно під пунктом меню */
  left: 50%;
  transform: translateX(-50%);
  background: #3fac8c; /* Задайте колір фону для кнопки */

  cursor: pointer;
  display: none; /* Приховуємо кнопку за замовчуванням */
  width: 145px;
  height: 49px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: Ubuntu;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 117.5%;
}
.filterButtonClick {
  position: absolute;
  top: 62px;
  left: 50%;
  transform: translateX(-50%);
  background: #f7cb25;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  width: 207px;
  height: 49px;
  color: #3e3092;
  font-family: Ubuntu;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 117.5%;
}
.navigationLiHover:hover .filterButton {
  /* Показуємо кнопку при наведенні на пункт меню */
}
.navigationLi a {
  text-decoration: none;
  font-size: 20px;
  color: #3e3092;
}
.filterMenuWrapHeadr {
  width: calc(95% - 40px);
  height: auto;
  border: 20px solid #f7cb25;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 20px;
  background: #f7cb25;
  position: absolute;
  z-index: 10;
  left: 2.5%;
}
.firstBlockWrap {
  width: 19.5%;
  height: auto;
  padding-left: 69px;
  padding-top: 32px;
  padding-bottom: 43px;
  padding-right: 15px;
  background: white;
  display: flex;
}
.ulFirstfilter {
  list-style-type: none;
}
.blockCheckFilterJoin {
  width: 16px;
  height: calc(20px - 4px);
  border: 2px solid #3e3092;
  margin-right: 7px;
  cursor: pointer;
  flex-shrink: 0;
}
.blockCheckFilterJoin.selected {
  width: 16px;
  height: calc(20px - 4px);
  border: 2px solid #3e3092;
  background: #3e3092;
  margin-right: 7px;
  cursor: pointer;
  flex-shrink: 0;
}
.ulFirstfilterLi {
  display: flex;
  cursor: pointer;
  align-items: center;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 117.5%;
  color: #3e3092;
  margin-bottom: 18px;
  height: auto;
}
.secondBlockWrapFilterProd {
  display: flex;
  flex-direction: column;
  background: white;
  height: calc(263px - 28px - 32px);
  padding-left: 26px;
  padding-top: 28px;
  padding-bottom: 32px;
  padding-right: 18px;
  width: 252px;
}

/* .secondBlockWrapFilterProd:nth-child(5n + 1) {
  width: calc(19% - 30px);
  margin-top: 20px;
}
.secondBlockWrapFilterProd:nth-child(5n + 2) {
  margin-left: 19px;
  width: calc(15% - 36px);
  margin-top: 20px;
}
.secondBlockWrapFilterProd:nth-child(5n + 3) {
  width: calc(18% - 30px);
  margin-left: 19px;
  margin-top: 20px;
}
.secondBlockWrapFilterProd:nth-child(5n + 4) {
  width: calc(18% - 30px);
  margin-left: 19px;
  margin-top: 20px;
}
.secondBlockWrapFilterProd:nth-child(5n + 5) {
  width: calc(20% - 30px);
  margin-left: 19px;
  margin-top: 20px;
} */
.secondBlockWrapFilterProdP {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 600;

  font-size: 20px;
  line-height: 117.5%;
  color: #3e3092;
}
.ulSecondfilter {
  list-style-type: none;
  margin: 0%;
  padding-left: 0;
  overflow-y: scroll;
}

.ulSecondfilter::-webkit-scrollbar {
  width: 6px;
}
.ulSecondfilter::-webkit-scrollbar-track {
  background: rgba(62, 48, 146, 0.5);
  border-radius: 10px;
}
.ulSecondfilter::-webkit-scrollbar-thumb {
  background: #3e3092;
  border-radius: 50px;
}
.ulSecondfilterLi {
  display: flex;
  cursor: pointer;
  align-items: center;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 117.5%;
  color: #3e3092;

  margin-bottom: 18px;
}
.buttonWrapFilter {
  display: flex;
  align-items: center;
  height: 263px;
  width: calc(41.3%);
  margin-left: 19px;
}
.twoButtonAllWrap {
  padding-top: 20px;
  width: calc(19%);
  height: calc(263px - 20px);
  display: flex;
  margin-left: 19px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.buttonAllBooksFilter {
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: calc(227px - 6px);
  height: 60px;
  border: 3px solid #3e3092;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 117.5%;
  color: #3e3092;
  cursor: pointer;
}
.wrapbuttonInMobile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
.buttonAllBooksFilterLink {
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 227px;
  height: 60px;
  border: none;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 117.5%;
  color: #3e3092;
}
.oneButtonAllWrap {
  width: calc(21%);
  margin-left: 19px;
  height: calc(263px - 20px);
  margin-top: 20px;
  display: flex;
  margin-left: 19px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
.buttonWatch {
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 227px;
  height: 60px;
  background: #3e3092;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #ffffff;
  line-height: 117.5%;
  cursor: pointer;
}

.burger {
  cursor: pointer;
}
.burgerWrap {
  position: fixed;
  top: 102px;
  background: #3e3092;
  width: 100%;
  height: 100vh;
  z-index: 3;
}
.smallWrap {
  width: calc(100% - 46px);
  height: auto;
  padding-left: 46px;
  position: relative;
}
.iconFromBurger {
  position: absolute;
  left: 85%;
  top: 20px;
  cursor: pointer;
}
.burgerList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 470px;
  padding-left: 0;
  margin-top: 43px;
}
.burgerListLi {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 117.5%;
  color: #ffffff;
  text-decoration: none;
  list-style-type: none;
}
.searchInputWrap {
  position: fixed;
  top: 60px;
  right: 0px;
  width: 600px;
  height: 60px;
  background: #3fac8c;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}
.searchInput {
  width: 582px;
  height: 44px;
  border: none;
  background: white;
  font-size: 30px;
}
.seachDo {
  position: absolute;
  right: 15px;
  cursor: pointer;
}
.suggestionsList {
  list-style: none;
  margin: 0;
  padding: 0;
  border: 1px solid #ddd;
  position: absolute;
  background: #fff;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
  width: 100%;
  top: 60px;
}
.liInSearchProduct {
  color: #3d3092;

  font-family: Ubuntu;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 152.5%;
  border-bottom: 1px solid #f7cb26;
}
.suggestionsList li {
  padding: 10px;
  cursor: pointer;
}

.suggestionsList li:hover {
  background-color: #f0f0f0;
}

@media only screen and (max-width: 600px) and (min-width: 300px) {
  .searchInputWrap {
    position: fixed;
    top: 60px;
    right: 0px;
    width: 274px;
    height: 60px;
    background: #3fac8c;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .searchInput {
    width: 256px;
    height: 44px;
    border: none;
    background: white;
    font-size: 30px;
  }
  .sunWrap {
    width: 100%;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: "Ubuntu";
    font-style: normal;
    font-size: 13px;
    font-weight: 700;
    display: flex;
    background-color: #3fac8c;
    -webkit-clip-path: none;
    clip-path: none;
  }
  .instaIcon {
    width: 34.66px;
    height: 33.65px;
    color: #3e3092;
  }
  .numberWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    background: white;
    border-radius: 50%;
  }
  .wrapSocial {
    width: 150px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .socialBlock {
    width: 56.85px;
    height: 55.82px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: white;
    cursor: pointer;
  }
  .secondBlockWrapFilterProd:nth-child(5n + 1) {
    width: calc(90% - 30px);
    margin-top: 0px;
  }
  .secondBlockWrapFilterProd:nth-child(5n + 2) {
    margin-left: 0;
    width: calc(90% - 30px);
    margin-top: 0px;
  }
  .secondBlockWrapFilterProd:nth-child(5n + 3) {
    width: calc(90% - 30px);
    margin-left: 0px;
    margin-top: 0px;
  }
  .secondBlockWrapFilterProd:nth-child(5n + 4) {
    width: calc(90% - 30px);
    margin-left: 0px;
    margin-top: 0px;
  }
  .secondBlockWrapFilterProd:nth-child(5n + 5) {
    width: calc(90% - 30px);
    margin-left: 0px;
    margin-top: 0px;
  }
  .buttonAllBooksFilter {
    width: calc(100% - 6px);
    margin-top: 20px;
  }
  .buttonWatch {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
  }
  .buttonWatcha {
    background: #3e3092;
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #ffffff;
    line-height: 117.5%;
    cursor: pointer;
    text-decoration: none;
  }
}
@media only screen and (max-width: 700px) and (min-width: 479px) {
  .sunWrap {
    width: 100%;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: "Ubuntu";
    font-style: normal;
    font-size: 13px;
    font-weight: 700;
    display: flex;
    background-color: #3fac8c;
    -webkit-clip-path: none;
    clip-path: none;
  }
  .numberWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    background: white;
    border-radius: 50%;
  }
}
@media only screen and (max-width: 1420px) and (min-width: 1024px) {
  .navigationLi {
    list-style: none;
    font-family: "Ubuntu";
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    color: #3e3092;
    position: relative;
    min-width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media only screen and (max-width: 1024px) and (min-width: 701px) {
  .sunWrap {
    width: 100%;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: "Ubuntu";
    font-style: normal;
    font-size: 13px;
    font-weight: 700;
    display: flex;
    background-color: #3fac8c;
    -webkit-clip-path: none;
    clip-path: none;
  }
  .numberWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    background: white;
    border-radius: 50%;
  }
}
