.umovaBlockWrap {
  width: 100%;
  height: 168px;
  background: #3e51c1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.umpvuP {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 111%;
  /* or 38px */

  text-transform: uppercase;
  margin-left: 44px;
  color: #ffffff;
}
.umWrap {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: calc(100% - 148px);
  height: auto;
  padding-right: 57px;
  padding-left: 91px;
  padding-top: 27px;
  padding-bottom: 20px;
  border-bottom: 25px solid #3e51c1;
}
.profesion {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 80%;
  height: auto;
}
.profesionDescH1 {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 111%;
  /* or 27px */
  margin-bottom: 0;
  width: 64%;
  color: #3e51c1;
}
.profesionDescP {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 111%;
  /* or 22px */
  width: 60%;

  color: #000000;
}
.profButtonWrap {
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}
.buttonOrderProf {
  width: 227px;
  height: 60px;
  border: none;
  background: #f7cb25;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 117.5%;
  color: #3e3092;
  margin-right: 21px;
  cursor: pointer;
}
.buttonOrderAdd {
  width: 227px;
  height: 60px;
  background: #3fac8c;
  border: none;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 117.5%;
  color: #ffffff;
}
.lite {
  width: 100%;
  height: 58px;
}
@media only screen and (max-width: 599px) and (min-width: 300px) {
  .umpvuP {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    width: 30%;
    line-height: 111%;
    /* or 38px */

    text-transform: uppercase;
    margin-left: 1px;
    color: #ffffff;
  }
  .umWrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: calc(100% - 20px);
    height: auto;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 27px;
    padding-bottom: 20px;
    border-bottom: 25px solid #3e51c1;
  }
  .profesion {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: auto;
  }
  .profesionDescH1 {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 111%;
    /* or 27px */
    margin-bottom: 0;
    width: 90%;
    color: #3e51c1;
  }
  .profesionDescP {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 111%;
    /* or 22px */
    width: 90%;

    color: #000000;
  }
  .profButtonWrap {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    width: 100%;
  }
  .buttonOrderProf {
    width: 300px;
    height: 56px;
    border: none;
    background: #f7cb25;
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 117.5%;
    color: #3e3092;
    margin-right: 0px;
  }
  .buttonOrderAdd {
    width: 300px;
    height: 56px;
    background: #3fac8c;
    border: none;
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 117.5%;
    color: #ffffff;
    margin-top: 10px;
  }
}
@media only screen and (max-width: 700px) and (min-width: 600px) {
  .umpvuP {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    width: 30%;
    line-height: 111%;
    /* or 38px */

    text-transform: uppercase;
    margin-left: 1px;
    color: #ffffff;
  }
  .umWrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: calc(100% - 20px);
    height: auto;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 27px;
    padding-bottom: 20px;
    border-bottom: 25px solid #3e51c1;
  }
  .profesion {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: auto;
  }
  .profesionDescH1 {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 111%;
    /* or 27px */
    margin-bottom: 0;
    width: 90%;
    color: #3e51c1;
  }
  .profesionDescP {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 111%;
    /* or 22px */
    width: 90%;

    color: #000000;
  }
  .profButtonWrap {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    width: 100%;
  }
  .buttonOrderProf {
    width: 300px;
    height: 56px;
    border: none;
    background: #f7cb25;
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 117.5%;
    color: #3e3092;
    margin-right: 0px;
  }
  .buttonOrderAdd {
    width: 300px;
    height: 56px;
    background: #3fac8c;
    border: none;
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 117.5%;
    color: #ffffff;
    margin-top: 10px;
  }
}
@media only screen and (max-width: 1288px) and (min-width: 1024px) {
}
@media only screen and (max-width: 1024px) and (min-width: 701px) {
  .umpvuP {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    width: 30%;
    line-height: 111%;
    /* or 38px */

    text-transform: uppercase;
    margin-left: 1px;
    color: #ffffff;
  }
  .umWrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: calc(100% - 20px);
    height: auto;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 27px;
    padding-bottom: 20px;
    border-bottom: 25px solid #3e51c1;
  }
  .profesion {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: auto;
  }
  .profesionDescH1 {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 111%;
    /* or 27px */
    margin-bottom: 0;
    width: 90%;
    color: #3e51c1;
  }
  .profesionDescP {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 111%;
    /* or 22px */
    width: 90%;

    color: #000000;
  }
  .profButtonWrap {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    width: 100%;
  }
  .buttonOrderProf {
    width: 300px;
    height: 56px;
    border: none;
    background: #f7cb25;
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 117.5%;
    color: #3e3092;
    margin-right: 0px;
  }
  .buttonOrderAdd {
    width: 300px;
    height: 56px;
    background: #3fac8c;
    border: none;
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 117.5%;
    color: #ffffff;
    margin-top: 10px;
  }
}
