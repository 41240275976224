.responseForm {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
}

.addRespH {
  color: #3e51c1;

  font-family: Ubuntu;
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: 121%;
}
.nameVid {
  color: #000;
  margin-top: 10px;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}
.wrapVidW {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.inputName {
  border: 2px solid #3fac8c;
  height: 40px;
  width: 600px;
  margin-top: 5px;
}
.inputVidguk {
  border: 2px solid #3fac8c;
  margin-top: 5px;
  width: 600px;
  height: 150px;
  margin-bottom: 20px;
}
.pictureA {
  display: none; /* Приховуємо стандартний інпут */
}

.uploadButton {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;

  text-align: center;
  font-family: Ubuntu;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 117.5%;
  background-color: #3fac8c;
  border: none;

  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  margin-top: 10px;
  margin-bottom: 10px;
}

.uploadButton:hover {
  background-color: #3fac8c;
}

.uploadButton:focus {
  outline: 2px solid #3fac8c;
  outline-offset: 2px;
}

.uploadButton:active {
  transform: scale(0.98);
}

.uploadButton:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.wrapVidWN input[type="checkbox"] {
  display: none;
}

.wrapVidWN input[type="checkbox"] + span {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #3fac8c;
  border-radius: 4px;
  position: relative;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  cursor: pointer;
}

.wrapVidWN input[type="checkbox"]:checked + span {
  background-color: #3fac8c;
  border-color: #3fac8c;
}

.wrapVidWN input[type="checkbox"]:checked + span::after {
  content: "";
  position: absolute;
  top: 3px;
  left: 6px;
  width: 6px;
  height: 12px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.checkLabel {
  display: flex;
  align-items: center;

  gap: 10px;
}
.authMessage {
  color: #3e51c1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Ubuntu;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 121%;
}
@media only screen and (max-width: 600px) and (min-width: 300px) {
  .inputVidguk {
    border: 2px solid #3fac8c;
    margin-top: 5px;
    width: 95%;
    height: 150px;
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 700px) and (min-width: 599px) {
  .inputVidguk {
    border: 2px solid #3fac8c;
    margin-top: 5px;
    width: 95%;
    height: 150px;
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 1288px) and (min-width: 1024px) {
}
@media only screen and (max-width: 1024px) and (min-width: 701px) {
}
@media only screen and (max-width: 1291px) and (min-width: 1100px) {
}
@media only screen and (max-width: 1369px) and (min-width: 1291px) {
}
@media only screen and (max-width: 1500px) and (min-width: 1369px) {
}
@media only screen and (max-width: 1600px) and (min-width: 1500px) {
}
