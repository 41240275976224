.mainPopWrap {
  width: calc(331px - 2px);
  height: auto;
  border: 2px solid #f7cb25;
  position: fixed;
  top: 170px;
  left: 75px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.mainPopWrapChose {
  width: calc(331px - 2px);
  height: auto;
  border: 2px solid #f7cb25;
  position: fixed;
  top: 130px;
  left: 75px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.dropPopWrap {
  width: calc(331px - 2px);
  height: auto;
  border: 2px solid #f7cb25;
  position: fixed;
  top: 115px;
  left: 850px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.librPopWrap {
  width: calc(331px - 2px);
  height: auto;
  border: 2px solid #f7cb25;
  position: fixed;
  top: 250px;
  left: 850px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.suportPopWrap {
  width: calc(331px - 2px);
  height: auto;
  border: 2px solid #f7cb25;
  position: fixed;
  top: 360px;
  left: 850px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.popCHWrap {
  width: calc(331px - 2px);
  height: auto;
  border: 2px solid #f7cb25;
  position: fixed;
  top: 220px;
  left: 75px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.popSPWrap {
  width: calc(331px - 2px);
  height: auto;
  border: 2px solid #f7cb25;
  position: fixed;
  top: 270px;
  left: 550px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.musicWrap {
  width: auto;
  height: auto;
  border: 2px solid #f7cb25;
  position: absolute;
  top: 65px;
  left: 0px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.musicWrapOp {
  width: 320px;
  height: auto;
  border: 2px solid #f7cb25;
  position: absolute;
  top: 4280px;
  left: 200px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.mainPopP {
  color: #3e3092;
  text-align: center;
  font-size: 14px;
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 500;
  line-height: 123%;
  margin: 0;
}
