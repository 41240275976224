.allWrapMainPage {
  overflow: hidden;
}
.bannerWrapperAll {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 585px;
  background: #d6cfff;
}
.bannerWrapper {
  width: 100%;
  height: auto;

  display: flex;
  align-items: center;
  justify-content: space-around;
}
.bannerText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 96px;
  width: 30%;
  height: 100%;
}
.bannerIcon {
  overflow: hidden;
}
.banerH1 {
  font-family: "Ubuntu";
  margin-bottom: 0;
  font-weight: 700;
  font-size: 34px;
  color: #3e3092;
}
.bannerP {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 600;
  margin-bottom: 40px;
  font-size: 20px;
  line-height: 28px;
  color: #3e3092;
}
.bannerButton {
  cursor: pointer;
  display: flex;
  border: none;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 10px;
  background: #3e3092;
  width: 298px;
  height: 58px;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 117.5%;
  color: #ffffff;
}
.arrovIconBanner {
  cursor: pointer;
  flex-shrink: 0;
}
.largeIcon {
  width: 972.27px;
  height: 512.83px;
}

.smallIcon {
  width: 381px;
  height: 201px;
}
.opWrap {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.obSmallWrap {
  width: 86%;
  height: 517.75px;
  margin: 30px;

  display: flex;
  flex-direction: column;
}
.obcladH1 {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 111%;
  margin: 0;
  color: #3fac8c;
}
.wrapTwoPic {
  display: flex;
  justify-content: space-around;
  align-self: center;
  justify-self: end;
  width: 75%;
  height: 433.75px;
  margin-top: 40px;
}
.wrapPicAndIcon {
  display: flex;
}
.picOb {
  width: 306px;
  height: 405px;
  object-fit: cover;
  margin-top: 30px;
}
.likeBook {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 67px;
  height: 67px;
  background: #d9d9d9;
  transform: rotate(45deg);
  cursor: pointer;
  align-self: flex-end;
  margin-left: 20px;
}
.likeBook.selected {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 67px;
  height: 67px;
  background: #f7cb25;
  transform: rotate(45deg);
  cursor: pointer;
  align-self: flex-end;
  margin-left: 20px;
}
.likeBookIcon {
  transform: rotate(-45deg);
}
.predProdazWrap {
  display: flex;
  border: 20px solid #3fac8c;
  width: calc(100% - 40px);
  height: auto;
  padding-bottom: 40px;
}
.imgPredProdag {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 40%;
  height: 100%;
}
.imgRelDiv {
  position: relative;
  width: 434px;
  height: 578px;
  margin-top: 30px;
}

.imgRelDivImg {
  margin-top: 20px;

  object-fit: cover;
  box-shadow: 5px 2px 5px #c3c3c3;
  width: 432px;
  height: 577px;
}
.newBookImgImg {
  width: 100%;
  height: 100%;
}
.imgRelDivImg {
  position: absolute;
}
.prdeProdPricing {
  position: absolute;
  left: 372px;
  top: 320px;
  z-index: 1;
  width: 120.92px;
  height: 120.92px;
  color: white;
  background: #3fac8c;
  transform: rotate(45deg);
  font-family: "Ubuntu";
  display: flex;

  align-items: center;
  justify-content: center;
}
.prdeProdPricingRotate {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: rotate(-45deg);
}
.fullPrice {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 152.5%;
  /* or 27px */

  text-align: center;
  margin: 0;
}
.fullPriceKr {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 152.5%;
  /* or 27px */
  text-decoration: line-through;
  text-decoration-color: #ff3416;
  text-align: center;
  margin: 0;
}
.salePrice {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 111%;
  color: #ffffff;
  margin: 0;
}
.infoPredProdag {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 60%;
  height: 100%;
  padding-left: 100px;
}
.infoPredProdagNewBooks {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 95%;
  height: 100%;
  padding-bottom: 27px;
}
.timerWrap {
  display: flex;
  width: 530px;
}
.redBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 249px;
  height: 67px;
  background: #ff3416;
}
.redBlock h1 {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 111%;
  color: #ffffff;
}
.timerBlue {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 249px;
  height: 67px;
  background: #3e51c1;
}
.timerBlue h2 {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 111%;
  text-align: center;
  margin: 0;
  color: #ffffff;
}
.timer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}
.timer h1 {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  margin: 0;
  line-height: 111%;
  text-align: center;
  color: #ffffff;
}
.nameBooks {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 111%;
  color: #3e3092;
  text-decoration: none;
}
.nameBooksNewBooks {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 111%;
  margin: 0;
  color: #3e3092;
}
.nameBooksNew {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 111%;
  color: #000000;
}
.autorInform {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 80%;
  height: auto;
  /* flex-wrap: wrap; */
}
.autorInformNew {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: auto;
}
.autorInformSection {
  display: flex;
  width: 100%;
  height: auto;
  align-items: flex-start;
  justify-content: flex-start;
}
.autorInformSectionTwo {
  display: flex;
  width: 50%;
  height: auto;
  align-items: flex-start;
  justify-content: flex-start;
}
.wrapInFgAuth {
  width: 50%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.wrapInFgAuthQW {
  width: 50%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.autorInformSectionTwo p {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 152.5%;
  color: #000000;
  margin: 0;
}
.autorInformSection p {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 152.5%;
  color: #000000;
  margin: 0;
}
.autorInformSectionTwo h4 {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 152.5%;
  margin: 0;
}
.autorInformSection h4 {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 152.5%;
  margin: 0;
}
.descriptionBooks {
  width: 90%;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 130%;
  color: #000000;
}
.descriptionBooksNew {
  width: 99%;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 130%;
  color: #000000;
  max-height: 280px;
}
.blockOrderBooksWrap {
  width: 90%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
.kOrderBooksPredButton {
  cursor: pointer;
  width: 227px;
  height: 60px;
  border: none;
  background: #3e51c1;
  color: white;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 117.5%;
}

/* boooks Age*/

.bookAgeWrap {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bookAgeWrapSmall {
  width: 86%;
  height: auto;
  height: 310.38px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: 57px;
  margin-right: 10px;
}
.chousBooks {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 70%;
}
.chousBooksH1 {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 111%;
  color: #3fac8c;
}
.blockButtonSelectWrap {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.chousBooksSelect {
  position: relative;
}
.customSelect {
  cursor: pointer;
  border: 3px solid #3fac8c;
  width: 415px;
  height: 84px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  padding-left: 25px;
  appearance: none;
}
.customArrowSelect {
  position: absolute;
  top: 36.5px;
  right: 30px;
  pointer-events: none;
}

.customOpin:checked {
  background: #3fac8c;
}
.buttonSelect {
  border: none;
  width: 297px;
  height: 84px;
  background: #f7cb25;
  font-family: "Ubuntu";
  cursor: pointer;
  font-weight: 500;
  font-size: 20px;
  line-height: 117.5%;
  color: #3e3092;
  margin-left: 46px;
}
/*saleblock*/
.saleBlockWrap {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.saleBlockWrapSmall {
  width: 88%;
  height: auto;
  margin: 76px;
  margin-left: 95px;

  height: auto;
  display: flex;
  flex-direction: column;
}
.saleBlockText {
  display: flex;
  align-items: center;
  margin-left: 12px;
}
.saleActions {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 111%;
  margin: 0;
  color: #ff3416;
}
.saleActionsTop {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 111%;
  margin: 0;
  color: #3e51c1;
}
.saleActionsLink {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 34px;
  line-height: 111%;
  color: #3e3092;
  margin-left: 20px;
}
.saleActionsLinkTop {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 34px;
  line-height: 111%;
  color: #ff3416;
  margin-left: 20px;
}
.smalProductList {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 40px;
}

.saleActionsPrice {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 111%;
  margin: 0;
  color: #3fac8c;
}
.saleActionsLinkPrice {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 34px;
  line-height: 111%;
  color: #3e51c1;
  margin-left: 20px;
}
.newBooksWrapBlock {
  width: 100%;
  height: 100vh;
  display: flex;
}
.yellowBorderWrap {
  width: calc(50% - 20px);
  border: 20px solid #f7cb25;
  padding-left: 49px;
  padding-top: 31.25px;
  padding-bottom: 27px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  overflow: hidden;
}

.blueBorderWrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: calc(50% - 20px);
  border: 20px solid #3e51c1;
  border-left: none;
  padding-left: 49px;
  padding-top: 31.25px;
}
.arrowLeft {
  cursor: pointer;
}
.prodPricLikeCart {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 94%;
  margin-top: 20px;
  height: auto;
}
.likeProductSmallSaleNovu {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 53px;
  height: 53px;
  background: #3e51c1;
}
.likeProductSmallSaleNovuGr {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 53px;
  height: 53px;
  background: #3fac8c;
}
.likeProductSmallSaleNovuGrClick {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 53px;
  height: 53px;
  background: #ff3416;
}
.imgPictureBox {
  position: relative;
  width: 72%;
  height: 100%;
}
.imgPictureBoxOpicun {
  position: relative;
  width: 85%;
}
.opicunPicture {
  width: 525px;
  height: 535px;
  object-fit: fill;
}
.markerNew {
  position: absolute;
  top: 400px;
  left: -22px;
  width: 111px;
  height: 49px;
  background: #ff3416;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 111%;
}
.BorderWrapBlurOp {
  position: relative;
  width: calc(50% - 69px);
  border: 20px solid #3e3092;
  padding-right: 49px;
  padding-top: 31.25px;
  padding-bottom: 27px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}
.markerSun {
  position: absolute;
  top: 200px;
  left: 80%;
  width: 111px;
  height: 49px;
  background: #ff3416;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 111%;
}
.opRadaButton {
  width: 320px;
  height: 60px;
  background: #3fac8c;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 117.5%;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  border: none;
  color: #ffffff;
  align-self: flex-start;
}

.OpradaRelPrice {
  position: absolute;
  left: -9%;
  top: 8%;
  z-index: 1;
  width: 109.58px;
  height: 109.58px;
  color: white;
  background: #ff3416;
  transform: rotate(45deg);
  font-family: "Ubuntu";
  display: flex;

  align-items: center;
  justify-content: center;
}
.OpradaRelPriceRotate {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: rotate(-45deg);
}
.fullPriceOpRada {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 152.5%;
  text-align: center;
  margin: 0;
}
.salePriceOpRada {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 111%;
  /* or 49px */

  display: flex;
  align-items: center;
  text-align: center;
  margin: 0;
  color: #ffffff;
}
.GreenBlockMal {
  background: #3fac8c;
  width: calc(50% - 49px);
  padding-left: 49px;
  padding-top: 51.25px;
  padding-bottom: 47px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.HelpCreateBooks {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 111%;
  width: 70%;
  margin: 0;
  color: #3e3092;
}
.giveDiscount {
  color: #fff;
  font-size: 18px;
  font-family: Ubuntu;
  font-weight: 500;
  line-height: 137%;
  width: 70%;

  /* or 27px */

  color: #ffffff;
}
.nameBooksNewBooksMal {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 111%;
  /* identical to box height, or 38px */

  color: #ffffff;
}
.descriptionOpikunBook {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 135.5%;
  color: #ffffff;
}
.whoyIsOpRada {
  width: 320px;
  height: 60px;
  background: #3e3092;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 117.5%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  color: #ffffff;
  align-self: flex-end;
  padding: 0;
}
.questionWrap {
  width: calc(100% - 136px);
  height: auto;
  padding-left: 68px;
  padding-right: 68px;
  padding-top: 47px;
  padding-bottom: 47px;
}
.questionWrapSmall {
  border: 20px solid #3e51c1;
  width: calc(100% - 40px);
  height: auto;
  display: flex;
  flex-direction: column;

  justify-content: space-around;
}
.wrapBlockAw {
  display: flex;

  justify-content: space-around;
}
.pictureAnswerWrap {
  width: 26%;
  height: auto;
  margin-top: 30px;
  margin-left: 27px;
}
.pictureAnswer {
  width: 100%;
}
.questionsWrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 60%;
  padding-bottom: 34px;
}
.questionSinc {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 111%;
  display: flex;
  align-self: center;
  color: #3e51c1;
}
.descriptionQuestion {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;

  color: #000000;
}
.questionLi {
  margin: 34px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  list-style-type: none;
  font-family: "Ubuntu";
  font-style: normal;
  width: 80%;
  height: auto;
  font-size: 20px;

  color: #000000;
}
.QuestionUl {
  display: flex;

  align-items: center;
  margin-top: 0;
  padding: 0;
}
.checkQuestion {
  width: 35px;
  height: 35px;
  margin-right: 18px;
  border: 5px solid #3e51c1;
  flex-shrink: 0;
}
.checkQuestion.selected {
  width: 35px;
  height: 35px;
  margin-right: 18px;
  border: 5px solid #3e51c1;
  background: #3e51c1;
  flex-shrink: 0;
}
.questionButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  width: 227px;
  height: 60px;
  background: #3e51c1;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 117.5%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  align-self: center;
  cursor: pointer;
}
.blockAutorWrap {
  position: relative;
  width: 80%;
  height: auto;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  justify-content: flex-end;
}
.blockAutorBig {
  position: relative;
  width: 100%;

  height: auto;
}
.imgAutorWrap {
  margin-top: 97px;
  position: absolute;
  margin-left: 14%;
  width: 252.44px;
  height: 252.44px;
  rotate: 45deg;

  overflow: hidden;
}
.imgAutorWrapSmall {
  position: relative;

  width: 126.57px;
  height: 126.57px;
  rotate: 0deg;
}
.prodMpbileWrapToAutor {
  width: calc(100% - 30px);
  padding-left: 30px;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  overflow: hidden;
  overflow-x: scroll;
  white-space: nowrap;
  margin-bottom: 100px;
}
.imgAutor {
  position: absolute;
  top: -23%;
  left: -15%;
  bottom: -20%;
  right: -15%;
  width: 356.98px;
  height: 392.68px;
  rotate: -45deg;
}
.imgAutorSmall {
  position: absolute;
  width: 178.99px;
  height: 178.99px;
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}
.infoAutor {
  width: 50%;

  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.autorName {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  margin: 0;
  line-height: 121%;
  color: #3e51c1;
}
.autorDescription {
  color: #000;
  font-size: 14px;
  font-family: Ubuntu;
  line-height: 127%;
}
.autorYelowBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 130%;
  height: auto;
  background: #f7cb25;
}
.greenButtonCheck {
  width: 35px;
  height: 35px;
  background: #3fac8c;
  border: 5px solid #3fac8c;
  flex-shrink: 0;
}
.percentage {
  color: #3e51c1;

  font-feature-settings: "liga" off, "clig" off;
  font-family: Ubuntu;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28.5px;
  margin: 0;
  margin-left: 20px;
  margin-right: 20px;
  flex-shrink: 0;
}
.autorPYellow {
  font-family: "Ubuntu";
  font-style: italic;
  font-weight: 400;
  font-size: 18px;
  line-height: 121%;
  color: #000000;
  padding: 19px;
  margin: 0;
}
.hvist {
  position: absolute;

  width: 105.5px;
  height: 45.33px;
  bottom: 0;
  z-index: -1;
  left: calc(0% - 52.75px);
  clip-path: polygon(50% 0%, 0 100%, 100% 100%);
  background: #f7cb25;
}
.wrapAutorProduct {
  padding-top: 68px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wrapAutorProductSmoll {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.allProdactAutor {
  position: absolute;
  left: 12%;
  bottom: 20%;
  margin-left: 3%;
  width: 227px;
  height: 60px;
  background: #ff3416;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 117.5%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  color: #ffffff;
  z-index: 4;
}
.productAutorWrapSM {
  width: 22%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.smalProdName {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  margin-top: 0;
  line-height: 121%;
  color: #3e51c1;
  text-decoration: none;
}
.smallProdIcon {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.iconCartSMBlue {
  width: 60px;
  height: 60px;
  background: #3e51c1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.iconCartSMGreen {
  width: 60px;
  height: 60px;
  background: #3fac8c;
  display: flex;
  align-items: center;
  justify-content: center;
}
.picSmallProdAutor {
  width: 100%;
  height: 220px;
}
.blockForText {
  position: relative;
}
.AllBooksAutorH2 {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 121%;
  color: #3e51c1;
}
.abrakadabra {
  width: 130%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.allAutorsWrap {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.allAutorsWrapSmall {
  width: calc(100% - 136px - 4%);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 4%;
}
.ourAutor {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 111%;
  color: #ff3416;
}
.autorNameM {
  position: absolute;
  top: 90%;
  left: 70%;
  z-index: 100;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 121%;
  color: #3e51c1;
  overflow: visible;
  transform: rotate(-45deg);
  margin-bottom: 0;
  width: 126.57px;
}
.autorListSmal {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mainPageBlogWrap {
  padding-top: 119px;
  display: flex;
  flex-direction: column;
}
.mainPageBlogH2 {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 111%;
  color: #3e51c1;
  margin-left: 90px;
}
.blogFotoInMain {
  width: 100%;
  height: 100%;
}
.blogList {
  width: 100%;
  height: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  background: #3e51c1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.publishListWrap {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  width: 90%;
}
.blogProdPage {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  width: 376px;
}
.vidoPic {
  width: calc(376px - 28px);
  height: calc(224px - 28px);
  border: 14px solid #f7cb25;
}
.vidoPicImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.nameBlog {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 122%;
  color: #ffffff;
}
.descriptionBlogSmall {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 122%;
  color: #ffffff;
  margin: 0px;
}
.moreBlogBut {
  width: 227px;
  height: 60px;
  border: none;
  background: #f7cb25;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  cursor: pointer;
  line-height: 117.5%;
  color: #3e3092;
  text-decoration: none;
}
.videoViewAgeWrap {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  width: 100%;
  height: auto;
  padding-top: 68px;
  padding-bottom: 89px;
}
.vidoViewBlockVideo {
  position: relative;
  margin-left: 40px;
  border: 20px solid #3fac8c;
  width: calc(758px - 40px);
  height: calc(454px - 40px);
}
.zaVikomImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.playButton {
  width: 56px;
  position: absolute;
  top: calc(50% - 40.5px);
  right: calc(50% - 28px);
  height: 81px;
  background: #f7cb25;
  cursor: pointer;
  -webkit-clip-path: polygon(0% 0%, 0 0, 100% 50%, 0 100%, 0% 100%);
  clip-path: polygon(0% 0%, 0 0, 100% 50%, 0 100%, 0% 100%);
}
.zaVikomOpus {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 33%;
  height: 100%;
  justify-content: space-between;
}
.zaVikomOpusH1 {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 111%;
  color: #3e3092;
}
.zaVikomOpusP {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 122%;
  color: #3e3092;
}
.zaVikomOpusButton {
  margin-top: 40px;
  cursor: pointer;
  width: 227px;
  height: 60px;
  background: #3e3092;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 117.5%;
  color: #ffffff;
}
.subscribeWrap {
  width: 100%;
  height: 187px;
  background: #3e51c1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logSubscribe {
  margin-bottom: 30px;
}
.subscribeP {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 300;
  font-size: 34px;
  line-height: 111%;
  color: #ffffff;
}
.subscribePSpan {
  font-weight: 800;
}
.subscribeButton {
  width: 227px;
  height: 60px;
  background: #f7cb25;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 117.5%;
  /* identical to box height, or 24px */

  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  margin-left: 60px;
  color: #3e3092;
  cursor: pointer;
}
.polosa {
  height: 87px;
  width: 100%;
  background: white;
}
.linkSubscrib {
  text-decoration: none;
}
@media only screen and (max-width: 600px) and (min-width: 300px) {
  .bannerWrapperAll {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    background: #d6cfff;
  }
  .bannerWrapper {
    width: 100%;
    height: auto;

    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-around;
  }
  .bannerText {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 0px;
    width: 100%;
    height: 100%;
  }
  .obSmallWrap {
    width: 86%;
    height: auto;
    margin: 0px;
    display: flex;
    flex-direction: column;
  }
  .wrapTwoPic {
    display: flex;
    justify-content: flex-start;
    align-self: center;
    overflow: hidden;
    overflow-x: scroll;
    white-space: nowrap;
    justify-self: end;
    width: 100%;
    height: auto;
    margin-top: 21x;
  }
  .picOb {
    width: 289.993px;
    height: 383.814px;
    object-fit: cover;
    margin-top: 30px;
  }
  .wrapPicAndIcon {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 23px;
  }
  .likeBook {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 67px;
    height: 67px;
    background: #d9d9d9;
    transform: rotate(45deg);
    cursor: pointer;
    align-self: center;
    margin-left: 0px;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .likeBook.selected {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 67px;
    height: 67px;
    background: #f7cb25;
    transform: rotate(45deg);
    cursor: pointer;
    align-self: center;
    margin-left: 0px;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .predProdazWrap {
    display: flex;
    flex-direction: column;
    border: 20px solid #3fac8c;
    width: calc(100% - 40px);
    height: auto;
    padding-bottom: 20px;
  }
  .timerWrap {
    display: flex;
    width: 100%;
  }
  .redBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 60px;
    background: #ff3416;
  }
  .redBlock h1 {
    color: #fff;
    font-size: 24px;
    font-family: Ubuntu;
    font-weight: 700;
    line-height: 111%;
  }
  .timerBlue {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 60px;
    background: #3e51c1;
  }
  .timerBlue h2 {
    text-align: center;
    font-size: 16px;
    font-family: Ubuntu;
    line-height: 111%;
    margin: 0;
    color: #ffffff;
  }
  .timer {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
  }
  .timer h1 {
    color: #fff;
    text-align: center;
    font-size: 20px;
    font-family: Ubuntu;
    font-weight: 700;
    line-height: 111%;
    margin: 0;
  }
  .imgPredProdag {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 276px;
    height: 369px;
    align-self: center;
    margin-top: 34px;
    margin-bottom: 60px;
  }
  .imgRelDiv {
    position: relative;
    width: 276px;
    height: 369px;
  }
  .imgRelDivImg {
    object-fit: cover;
    box-shadow: 5px 2px 5px #c3c3c3;
    width: 276px;
    height: 369px;
  }
  .prdeProdPricing {
    position: absolute;
    left: 40%;
    top: 332px;
    z-index: 1;
    width: 76.368px;
    height: 76.368px;

    color: white;
    background: #3fac8c;
    transform: rotate(45deg);
    font-family: "Ubuntu";
    display: flex;

    align-items: center;
    justify-content: center;
  }
  .prdeProdPricingRotate {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: rotate(-45deg);
  }
  .fullPrice {
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-family: Ubuntu;
    font-weight: 500;
    line-height: 152.5%;
    margin: 0;
  }
  .fullPriceKr {
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-family: Ubuntu;
    font-weight: 500;
    line-height: 152.5%;
    text-decoration: line-through;
    text-decoration-color: #ff3416;
    text-align: center;
    margin: 0;
  }
  .salePrice {
    color: #fff;
    text-align: center;
    font-size: 34px;
    font-family: Ubuntu;
    font-weight: 700;
    line-height: 111%;
    margin: 0;
  }
  .blockOrderBooksWrap {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
  }
  .kOrderBooksPredButton {
    cursor: pointer;
    width: 227px;
    height: 60px;
    border: none;
    background: #3e51c1;
    color: white;
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 117.5%;
    margin-bottom: 20px;
  }
  .infoPredProdag {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: calc(100% - 19px);
    height: 100%;
    padding-left: 19px;
  }
  .autorInform {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    height: auto;
    flex-wrap: wrap;
  }
  .bookAgeWrapSmall {
    width: 100%;
    height: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 0px;
    margin-right: 0px;
  }
  .blockButtonSelectWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .chousBooksSelect {
    position: relative;
    width: 100%;
  }
  .customSelect {
    cursor: pointer;
    border: 3px solid #3fac8c;
    width: 100%;
    height: 84px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    padding-left: 25px;
    appearance: none;
  }
  .buttonSelect {
    border: none;
    width: 100%;
    height: 84px;
    background: #f7cb25;
    font-family: "Ubuntu";
    cursor: pointer;
    font-weight: 500;
    font-size: 20px;
    line-height: 117.5%;
    color: #3e3092;
    margin-left: 0px;
  }
  .saleBlockWrapSmall {
    width: 88%;
    height: auto;
    margin: 10px;
    margin-left: 10px;
    margin-top: 49px;
    height: auto;
    display: flex;
    flex-direction: column;
  }
  .saleActions {
    color: #ff3416;
    font-size: 24px;
    font-family: Ubuntu;
    font-weight: 700;
    line-height: 111%;
    margin: 0;
  }
  .saleActionsLink {
    color: #3e3092;
    font-size: 24px;
    font-family: Ubuntu;
    font-weight: 500;
    line-height: 111%;
    text-decoration-line: underline;
    margin-left: 20px;
  }
  .smalProductList {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-top: 40px;
    overflow: hidden;
    overflow-x: scroll;
    white-space: nowrap;
  }
  .newBooksWrapBlock {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
  }
  .yellowBorderWrap {
    width: calc(100% - 40px);
    border: 20px solid #f7cb25;
    padding-left: 0px;
    padding-top: 31.25px;
    padding-bottom: 27px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .blueBorderWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 40px);
    border: 20px solid #3e51c1;
    border-left: 20px solid #3e51c1;
    padding-left: 0px;
    padding-top: 31.25px;
  }
  .imgPictureBox {
    position: relative;
    width: 95%;
  }
  .arrovIconBanner {
    cursor: pointer;
    margin-top: 40px;
  }
  .GreenBlockMal {
    position: relative;
    background: #3fac8c;
    width: calc(100% - 17px);
    padding-left: 17px;
    padding-top: 51.25px;
    padding-bottom: 47px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
  .HelpCreateBooks {
    color: #3e3092;
    font-size: 24px;
    font-family: Ubuntu;
    font-weight: 700;
    line-height: 111%;
    width: 90%;
    margin: 0;
  }
  .giveDiscount {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 1.25em;
    width: 70%;
    line-height: 137%;
    /* or 27px */

    color: #ffffff;
  }
  .nameBooksNewBooksMal {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 111%;
    /* identical to box height, or 38px */

    color: #ffffff;
  }
  .descriptionOpikunBook {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 135.5%;
    color: #ffffff;
  }
  .whoyIsOpRada {
    width: 320px;
    height: 60px;
    background: #3e3092;
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 117.5%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    color: #ffffff;
    align-self: flex-end;
    padding: 0;
  }
  .nameBooksNewBooksMal {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 111%;
    /* identical to box height, or 38px */

    color: #ffffff;
  }
  .descriptionOpikunBook {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 135.5%;
    color: #ffffff;
  }
  .whoyIsOpRada {
    width: 320px;
    height: 60px;
    background: #3e3092;
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 117.5%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    color: #ffffff;
    align-self: center;
    padding: 0;
  }
  .BorderWrapBlurOp {
    position: relative;
    width: calc(100% - 40px);
    border: 20px solid #3e3092;
    padding-right: 0;
    padding-top: 31.25px;
    padding-bottom: 27px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
  }
  .OpradaRelPrice {
    position: absolute;
    left: calc(50% - 54.5px);
    top: -12%;
    z-index: 1;
    width: 109.58px;
    height: 109.58px;
    color: white;
    background: #ff3416;
    transform: rotate(45deg);
    font-family: "Ubuntu";
    display: flex;

    align-items: center;
    justify-content: center;
  }
  .opRadaButton {
    width: 320px;
    height: 60px;
    background: #3fac8c;
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    margin-top: 47px;
    line-height: 117.5%;
    margin-bottom: 34px;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    border: none;
    color: #ffffff;
    align-self: center;
  }
  .markerSun {
    position: absolute;
    top: 200px;
    left: 70%;
    width: 111px;
    height: 49px;
    background: #ff3416;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 111%;
  }
  .questionWrap {
    width: calc(100%);
    height: auto;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 47px;
    padding-bottom: 47px;
  }
  .wrapBlockAw {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .pictureAnswerWrap {
    width: 80%;
    align-self: center;
    height: auto;
    margin-top: 30px;
    margin-left: 0px;
  }
  .questionsWrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    align-self: center;
    width: 95%;
    padding-bottom: 34px;
  }
  .imgAutorWrap {
    margin-top: 97px;
    position: absolute;
    margin-left: 14%;
    width: 76.368px;
    height: 76.368px;
    rotate: 45deg;

    overflow: hidden;
  }
  .imgAutor {
    position: absolute;
    top: -23%;
    left: -15%;
    bottom: -20%;
    right: -15%;
    width: 107.994px;
    height: 118.794px;
    rotate: -45deg;
  }
  .autorName {
    color: #3e51c1;
    font-size: 24px;
    font-family: Ubuntu;
    font-weight: 700;
    line-height: 121%;
    margin: 0;
  }
  .blockAutorWrap {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    justify-content: flex-end;
  }
  .infoAutor {
    width: 60%;

    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .autorYelowBlock {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 140%;
    right: 50%;
    height: auto;
    background: #f7cb25;
  }
  .hvist {
    position: absolute;

    width: 105.5px;
    height: 45.33px;
    top: -1%;
    left: -30px;
    z-index: -1;

    clip-path: polygon(50% 0%, 0 100%, 100% 100%);
    background: #f7cb25;
    rotate: 90deg;
  }
  .productAutorWrapSM {
    width: 70%;
    flex-shrink: 0;
    margin-right: 20px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
  .picSmallProdAutor {
    width: 100%;
    height: auto;
  }
  .smallProdIcon {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .allProdactAutor {
    position: absolute;
    left: calc(50% - 113.5px);
    bottom: -100px;

    width: 227px;
    height: 60px;
    background: #ff3416;
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 117.5%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    color: #ffffff;
  }
  .autorListSmal {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .imgAutorWrapSmall {
    position: relative;

    width: 126.57px;
    height: 126.57px;
    rotate: 0deg;
    margin-bottom: 90px;
  }
  .publishListWrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    width: 90%;
  }
  .blogList {
    width: 100%;
    height: auto;
    background: #3e51c1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding-bottom: 72px;
  }
  .mainPageBlogH2 {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 111%;
    color: #3e51c1;
    margin-left: 14px;
  }
  .blogProdPage {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    margin-top: 20px;
    margin-bottom: 40px;
  }
  .vidoPic {
    width: calc(100%- 28px);
    height: calc(100% - 28px);
    border: 14px solid #f7cb25;
  }
  .videoViewAgeWrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding-top: 68px;
    padding-bottom: 89px;
  }
  .vidoViewBlockVideo {
    position: relative;
    margin-left: 0px;
    border: 20px solid #3fac8c;
    width: calc(100% - 40px);
    height: calc(454px - 40px);
  }
  .zaVikomOpus {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: calc(100% - 10px);
    margin-left: 10px;
    height: 100%;
    justify-content: space-between;
  }
  .zaVikomOpusButton {
    align-self: center;
  }
  .subscribeWrap {
    width: 100%;
    height: auto;
    background: #3e51c1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 68px;
  }
  .logSubscribe {
    margin-bottom: 0px;
  }
  .subscribeButton {
    margin-left: 0;
  }
}
@media only screen and (max-width: 700px) and (min-width: 599px) {
  .bannerWrapperAll {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    background: #d6cfff;
  }
  .bannerWrapper {
    width: 100%;
    height: auto;

    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-around;
  }
  .bannerText {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 0px;
    width: 100%;
    height: 100%;
  }
  .obSmallWrap {
    width: 86%;
    height: auto;
    margin: 0px;
    display: flex;
    flex-direction: column;
  }
  .wrapTwoPic {
    display: flex;
    justify-content: flex-start;
    align-self: center;
    overflow: hidden;
    overflow-x: scroll;
    white-space: nowrap;
    justify-self: end;
    width: 100%;
    height: auto;
    margin-top: 21x;
  }
  .picOb {
    width: 289.993px;
    height: 383.814px;
    object-fit: cover;
    margin-top: 30px;
  }
  .wrapPicAndIcon {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 23px;
  }
  .likeBook {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 67px;
    height: 67px;
    background: #d9d9d9;
    transform: rotate(45deg);
    cursor: pointer;
    align-self: center;
    margin-left: 0px;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .likeBook.selected {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 67px;
    height: 67px;
    background: #f7cb25;
    transform: rotate(45deg);
    cursor: pointer;
    align-self: center;
    margin-left: 0px;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .predProdazWrap {
    display: flex;
    flex-direction: column;
    border: 20px solid #3fac8c;
    width: calc(100% - 40px);
    height: auto;
    padding-bottom: 20px;
  }
  .timerWrap {
    display: flex;
    width: 100%;
  }
  .redBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 60px;
    background: #ff3416;
  }
  .redBlock h1 {
    color: #fff;
    font-size: 24px;
    font-family: Ubuntu;
    font-weight: 700;
    line-height: 111%;
  }
  .timerBlue {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 60px;
    background: #3e51c1;
  }
  .timerBlue h2 {
    text-align: center;
    font-size: 16px;
    font-family: Ubuntu;
    line-height: 111%;
    margin: 0;
    color: #ffffff;
  }
  .timer {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
  }
  .timer h1 {
    color: #fff;
    text-align: center;
    font-size: 20px;
    font-family: Ubuntu;
    font-weight: 700;
    line-height: 111%;
    margin: 0;
  }
  .imgPredProdag {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 276px;
    height: 369px;
    align-self: center;
    margin-top: 34px;
    margin-bottom: 60px;
  }
  .imgRelDiv {
    position: relative;
    width: 276px;
    height: 369px;
  }
  .imgRelDivImg {
    object-fit: cover;
    box-shadow: 5px 2px 5px #c3c3c3;
    width: 276px;
    height: 369px;
  }
  .prdeProdPricing {
    position: absolute;
    left: 40%;
    top: 332px;
    z-index: 1;
    width: 76.368px;
    height: 76.368px;

    color: white;
    background: #3fac8c;
    transform: rotate(45deg);
    font-family: "Ubuntu";
    display: flex;

    align-items: center;
    justify-content: center;
  }
  .prdeProdPricingRotate {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: rotate(-45deg);
  }
  .fullPrice {
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-family: Ubuntu;
    font-weight: 500;
    line-height: 152.5%;
    margin: 0;
  }
  .fullPriceKr {
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-family: Ubuntu;
    font-weight: 500;
    line-height: 152.5%;
    text-decoration: line-through;
    text-decoration-color: #ff3416;
    text-align: center;
    margin: 0;
  }
  .salePrice {
    color: #fff;
    text-align: center;
    font-size: 34px;
    font-family: Ubuntu;
    font-weight: 700;
    line-height: 111%;
    margin: 0;
  }
  .blockOrderBooksWrap {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
  }
  .kOrderBooksPredButton {
    cursor: pointer;
    width: 227px;
    height: 60px;
    border: none;
    background: #3e51c1;
    color: white;
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 117.5%;
    margin-bottom: 20px;
  }
  .infoPredProdag {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: calc(100% - 19px);
    height: 100%;
    padding-left: 19px;
  }
  .autorInform {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    height: auto;
    flex-wrap: wrap;
  }
  .bookAgeWrapSmall {
    width: 100%;
    height: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 0px;
    margin-right: 0px;
  }
  .blockButtonSelectWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .chousBooksSelect {
    position: relative;
    width: 100%;
  }
  .customSelect {
    cursor: pointer;
    border: 3px solid #3fac8c;
    width: 100%;
    height: 84px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    padding-left: 25px;
    appearance: none;
  }
  .buttonSelect {
    border: none;
    width: 100%;
    height: 84px;
    background: #f7cb25;
    font-family: "Ubuntu";
    cursor: pointer;
    font-weight: 500;
    font-size: 20px;
    line-height: 117.5%;
    color: #3e3092;
    margin-left: 0px;
  }
  .saleBlockWrapSmall {
    width: 88%;
    height: auto;
    margin: 10px;
    margin-left: 10px;
    margin-top: 49px;
    height: auto;
    display: flex;
    flex-direction: column;
  }
  .saleActions {
    color: #ff3416;
    font-size: 24px;
    font-family: Ubuntu;
    font-weight: 700;
    line-height: 111%;
    margin: 0;
  }
  .saleActionsLink {
    color: #3e3092;
    font-size: 24px;
    font-family: Ubuntu;
    font-weight: 500;
    line-height: 111%;
    text-decoration-line: underline;
    margin-left: 20px;
  }
  .smalProductList {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-top: 40px;
    overflow: hidden;
    overflow-x: scroll;
    white-space: nowrap;
  }
  .newBooksWrapBlock {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
  }
  .yellowBorderWrap {
    width: calc(100% - 40px);
    border: 20px solid #f7cb25;
    padding-left: 0px;
    padding-top: 31.25px;
    padding-bottom: 27px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .blueBorderWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 40px);
    border: 20px solid #3e51c1;
    border-left: 20px solid #3e51c1;
    padding-left: 0px;
    padding-top: 31.25px;
  }
  .imgPictureBox {
    position: relative;
    width: 95%;
  }
  .arrovIconBanner {
    cursor: pointer;
    margin-top: 40px;
  }
  .GreenBlockMal {
    position: relative;
    background: #3fac8c;
    width: calc(100% - 17px);
    padding-left: 17px;
    padding-top: 51.25px;
    padding-bottom: 47px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
  .HelpCreateBooks {
    color: #3e3092;
    font-size: 24px;
    font-family: Ubuntu;
    font-weight: 700;
    line-height: 111%;
    width: 90%;
    margin: 0;
  }
  .giveDiscount {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 1.25em;
    width: 70%;
    line-height: 137%;
    /* or 27px */

    color: #ffffff;
  }
  .nameBooksNewBooksMal {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 111%;
    /* identical to box height, or 38px */

    color: #ffffff;
  }
  .descriptionOpikunBook {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 135.5%;
    color: #ffffff;
  }
  .whoyIsOpRada {
    width: 320px;
    height: 60px;
    background: #3e3092;
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 117.5%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    color: #ffffff;
    align-self: flex-end;
    padding: 0;
  }
  .nameBooksNewBooksMal {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 111%;
    /* identical to box height, or 38px */

    color: #ffffff;
  }
  .descriptionOpikunBook {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 135.5%;
    color: #ffffff;
  }
  .whoyIsOpRada {
    width: 320px;
    height: 60px;
    background: #3e3092;
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 117.5%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    color: #ffffff;
    align-self: center;
    padding: 0;
  }
  .BorderWrapBlurOp {
    position: relative;
    width: calc(100% - 40px);
    border: 20px solid #3e3092;
    padding-right: 0;
    padding-top: 31.25px;
    padding-bottom: 27px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
  }
  .OpradaRelPrice {
    position: absolute;
    left: calc(50% - 54.5px);
    top: -12%;
    z-index: 1;
    width: 109.58px;
    height: 109.58px;
    color: white;
    background: #ff3416;
    transform: rotate(45deg);
    font-family: "Ubuntu";
    display: flex;

    align-items: center;
    justify-content: center;
  }
  .opRadaButton {
    width: 320px;
    height: 60px;
    background: #3fac8c;
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    margin-top: 47px;
    line-height: 117.5%;
    margin-bottom: 34px;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    border: none;
    color: #ffffff;
    align-self: center;
  }
  .markerSun {
    position: absolute;
    top: 200px;
    left: 70%;
    width: 111px;
    height: 49px;
    background: #ff3416;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 111%;
  }
  .questionWrap {
    width: calc(100%);
    height: auto;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 47px;
    padding-bottom: 47px;
  }
  .wrapBlockAw {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .pictureAnswerWrap {
    width: 80%;
    align-self: center;
    height: auto;
    margin-top: 30px;
    margin-left: 0px;
  }
  .questionsWrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    align-self: center;
    width: 95%;
    padding-bottom: 34px;
  }
  .imgAutorWrap {
    margin-top: 97px;
    position: absolute;
    margin-left: 14%;
    width: 76.368px;
    height: 76.368px;
    rotate: 45deg;

    overflow: hidden;
  }
  .imgAutor {
    position: absolute;
    top: -23%;
    left: -15%;
    bottom: -20%;
    right: -15%;
    width: 107.994px;
    height: 118.794px;
    rotate: -45deg;
  }
  .autorName {
    color: #3e51c1;
    font-size: 24px;
    font-family: Ubuntu;
    font-weight: 700;
    line-height: 121%;
    margin: 0;
  }
  .blockAutorWrap {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    justify-content: flex-end;
  }
  .infoAutor {
    width: 60%;

    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .autorYelowBlock {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 140%;
    right: 50%;
    height: auto;
    background: #f7cb25;
  }
  .hvist {
    position: absolute;

    width: 105.5px;
    height: 45.33px;
    top: -1%;
    left: -30px;
    z-index: -1;

    clip-path: polygon(50% 0%, 0 100%, 100% 100%);
    background: #f7cb25;
    rotate: 90deg;
  }
  .productAutorWrapSM {
    width: 70%;
    flex-shrink: 0;
    margin-right: 20px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
  .picSmallProdAutor {
    width: 100%;
    height: auto;
  }
  .smallProdIcon {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .allProdactAutor {
    position: absolute;
    left: calc(50% - 113.5px);
    bottom: -100px;

    width: 227px;
    height: 60px;
    background: #ff3416;
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 117.5%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    color: #ffffff;
  }
  .autorListSmal {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .imgAutorWrapSmall {
    position: relative;

    width: 126.57px;
    height: 126.57px;
    rotate: 0deg;
    margin-bottom: 90px;
  }
  .publishListWrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    width: 90%;
  }
  .blogList {
    width: 100%;
    height: auto;
    background: #3e51c1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding-bottom: 72px;
  }
  .mainPageBlogH2 {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 111%;
    color: #3e51c1;
    margin-left: 14px;
  }
  .blogProdPage {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    margin-top: 20px;
    margin-bottom: 40px;
  }
  .vidoPic {
    width: calc(100%- 28px);
    height: calc(100% - 28px);
    border: 14px solid #f7cb25;
  }
  .videoViewAgeWrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding-top: 68px;
    padding-bottom: 89px;
  }
  .vidoViewBlockVideo {
    position: relative;
    margin-left: 0px;
    border: 20px solid #3fac8c;
    width: calc(100% - 40px);
    height: calc(454px - 40px);
  }
  .zaVikomOpus {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: calc(100% - 10px);
    margin-left: 10px;
    height: 100%;
    justify-content: space-between;
  }
  .zaVikomOpusButton {
    align-self: center;
  }
  .subscribeWrap {
    width: 100%;
    height: auto;
    background: #3e51c1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 68px;
  }
  .logSubscribe {
    margin-bottom: 0px;
  }
  .subscribeButton {
    margin-left: 0;
  }
}
@media only screen and (max-width: 1288px) and (min-width: 1024px) {
  .bannerText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-left: 20px;
    width: 30%;
    height: 100%;
  }
  .bannerP {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 600;
    margin-bottom: 15px;
    font-size: 17px;
    line-height: 28px;
    color: #3e3092;
  }
  .bookAgeWrapSmall {
    width: 100%;
    height: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 0px;
    margin-right: 0px;
  }
}
@media only screen and (max-width: 1024px) and (min-width: 701px) {
  .bannerWrapperAll {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    background: #d6cfff;
  }
  .bannerWrapper {
    width: 100%;
    height: auto;

    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-around;
  }
  .bannerText {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 0px;
    width: 100%;
    height: 100%;
  }
  .obSmallWrap {
    width: 86%;
    height: auto;
    margin: 0px;
    display: flex;
    flex-direction: column;
  }
  .wrapTwoPic {
    display: flex;
    justify-content: flex-start;
    align-self: center;
    overflow: hidden;
    overflow-x: scroll;
    white-space: nowrap;
    justify-self: end;
    width: 100%;
    height: auto;
    margin-top: 21x;
  }
  .picOb {
    width: 289.993px;
    height: 383.814px;
    object-fit: cover;
    margin-top: 30px;
  }
  .wrapPicAndIcon {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 23px;
  }
  .likeBook {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 67px;
    height: 67px;
    background: #d9d9d9;
    transform: rotate(45deg);
    cursor: pointer;
    align-self: center;
    margin-left: 0px;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .likeBook.selected {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 67px;
    height: 67px;
    background: #f7cb25;
    transform: rotate(45deg);
    cursor: pointer;
    align-self: center;
    margin-left: 0px;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .predProdazWrap {
    display: flex;
    flex-direction: column;
    border: 20px solid #3fac8c;
    width: calc(100% - 40px);
    height: auto;
    padding-bottom: 20px;
  }
  .timerWrap {
    display: flex;
    width: 100%;
  }
  .redBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 60px;
    background: #ff3416;
  }
  .redBlock h1 {
    color: #fff;
    font-size: 24px;
    font-family: Ubuntu;
    font-weight: 700;
    line-height: 111%;
  }
  .timerBlue {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 60px;
    background: #3e51c1;
  }
  .timerBlue h2 {
    text-align: center;
    font-size: 16px;
    font-family: Ubuntu;
    line-height: 111%;
    margin: 0;
    color: #ffffff;
  }
  .timer {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
  }
  .timer h1 {
    color: #fff;
    text-align: center;
    font-size: 20px;
    font-family: Ubuntu;
    font-weight: 700;
    line-height: 111%;
    margin: 0;
  }
  .imgPredProdag {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 276px;
    height: 369px;
    align-self: center;
    margin-top: 34px;
    margin-bottom: 60px;
  }
  .imgRelDiv {
    position: relative;
    width: 276px;
    height: 369px;
  }
  .imgRelDivImg {
    object-fit: cover;
    box-shadow: 5px 2px 5px #c3c3c3;
    width: 276px;
    height: 369px;
  }
  .prdeProdPricing {
    position: absolute;
    left: 40%;
    top: 332px;
    z-index: 1;
    width: 76.368px;
    height: 76.368px;

    color: white;
    background: #3fac8c;
    transform: rotate(45deg);
    font-family: "Ubuntu";
    display: flex;

    align-items: center;
    justify-content: center;
  }
  .prdeProdPricingRotate {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: rotate(-45deg);
  }
  .fullPrice {
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-family: Ubuntu;
    font-weight: 500;
    line-height: 152.5%;
    margin: 0;
  }
  .fullPriceKr {
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-family: Ubuntu;
    font-weight: 500;
    line-height: 152.5%;
    text-decoration: line-through;
    text-decoration-color: #ff3416;
    text-align: center;
    margin: 0;
  }
  .salePrice {
    color: #fff;
    text-align: center;
    font-size: 34px;
    font-family: Ubuntu;
    font-weight: 700;
    line-height: 111%;
    margin: 0;
  }
  .blockOrderBooksWrap {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
  }
  .kOrderBooksPredButton {
    cursor: pointer;
    width: 227px;
    height: 60px;
    border: none;
    background: #3e51c1;
    color: white;
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 117.5%;
    margin-bottom: 20px;
  }
  .infoPredProdag {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: calc(100% - 19px);
    height: 100%;
    padding-left: 19px;
  }
  .autorInform {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    height: auto;
    flex-wrap: wrap;
  }
  .bookAgeWrapSmall {
    width: 100%;
    height: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 0px;
    margin-right: 0px;
  }
  .blockButtonSelectWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .chousBooksSelect {
    position: relative;
    width: 100%;
  }
  .customSelect {
    cursor: pointer;
    border: 3px solid #3fac8c;
    width: 100%;
    height: 84px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    padding-left: 25px;
    appearance: none;
  }
  .buttonSelect {
    border: none;
    width: 100%;
    height: 84px;
    background: #f7cb25;
    font-family: "Ubuntu";
    cursor: pointer;
    font-weight: 500;
    font-size: 20px;
    line-height: 117.5%;
    color: #3e3092;
    margin-left: 0px;
  }
  .saleBlockWrapSmall {
    width: 88%;
    height: auto;
    margin: 10px;
    margin-left: 10px;
    margin-top: 49px;
    height: auto;
    display: flex;
    flex-direction: column;
  }
  .saleActions {
    color: #ff3416;
    font-size: 24px;
    font-family: Ubuntu;
    font-weight: 700;
    line-height: 111%;
    margin: 0;
  }
  .saleActionsLink {
    color: #3e3092;
    font-size: 24px;
    font-family: Ubuntu;
    font-weight: 500;
    line-height: 111%;
    text-decoration-line: underline;
    margin-left: 20px;
  }
  .smalProductList {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-top: 40px;
    overflow: hidden;
    overflow-x: scroll;
    white-space: nowrap;
  }
  .newBooksWrapBlock {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
  }
  .yellowBorderWrap {
    width: calc(100% - 40px);
    border: 20px solid #f7cb25;
    padding-left: 0px;
    padding-top: 31.25px;
    padding-bottom: 27px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .blueBorderWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 40px);
    border: 20px solid #3e51c1;
    border-left: 20px solid #3e51c1;
    padding-left: 0px;
    padding-top: 31.25px;
  }
  .imgPictureBox {
    position: relative;
    width: 95%;
  }
  .arrovIconBanner {
    cursor: pointer;
    margin-top: 40px;
  }
  .GreenBlockMal {
    position: relative;
    background: #3fac8c;
    width: calc(100% - 17px);
    padding-left: 17px;
    padding-top: 51.25px;
    padding-bottom: 47px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
  .HelpCreateBooks {
    color: #3e3092;
    font-size: 24px;
    font-family: Ubuntu;
    font-weight: 700;
    line-height: 111%;
    width: 90%;
    margin: 0;
  }
  .giveDiscount {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 1.25em;
    width: 70%;
    line-height: 137%;
    /* or 27px */

    color: #ffffff;
  }
  .nameBooksNewBooksMal {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 111%;
    /* identical to box height, or 38px */

    color: #ffffff;
  }
  .descriptionOpikunBook {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 135.5%;
    color: #ffffff;
  }
  .whoyIsOpRada {
    width: 320px;
    height: 60px;
    background: #3e3092;
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 117.5%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    color: #ffffff;
    align-self: flex-end;
    padding: 0;
  }
  .nameBooksNewBooksMal {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 111%;
    /* identical to box height, or 38px */

    color: #ffffff;
  }
  .descriptionOpikunBook {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 135.5%;
    color: #ffffff;
  }
  .whoyIsOpRada {
    width: 320px;
    height: 60px;
    background: #3e3092;
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 117.5%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    color: #ffffff;
    align-self: center;
    padding: 0;
  }
  .BorderWrapBlurOp {
    position: relative;
    width: calc(100% - 40px);
    border: 20px solid #3e3092;
    padding-right: 0;
    padding-top: 31.25px;
    padding-bottom: 27px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
  }
  .OpradaRelPrice {
    position: absolute;
    left: calc(50% - 54.5px);
    top: -12%;
    z-index: 1;
    width: 109.58px;
    height: 109.58px;
    color: white;
    background: #ff3416;
    transform: rotate(45deg);
    font-family: "Ubuntu";
    display: flex;

    align-items: center;
    justify-content: center;
  }
  .opRadaButton {
    width: 320px;
    height: 60px;
    background: #3fac8c;
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    margin-top: 47px;
    line-height: 117.5%;
    margin-bottom: 34px;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    border: none;
    color: #ffffff;
    align-self: center;
  }
  .markerSun {
    position: absolute;
    top: 200px;
    left: 70%;
    width: 111px;
    height: 49px;
    background: #ff3416;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 111%;
  }
  .questionWrap {
    width: calc(100%);
    height: auto;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 47px;
    padding-bottom: 47px;
  }
  .wrapBlockAw {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .pictureAnswerWrap {
    width: 80%;
    align-self: center;
    height: auto;
    margin-top: 30px;
    margin-left: 0px;
  }
  .questionsWrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    align-self: center;
    width: 95%;
    padding-bottom: 34px;
  }
  .imgAutorWrap {
    margin-top: 97px;
    position: absolute;
    margin-left: 14%;
    width: 76.368px;
    height: 76.368px;
    rotate: 45deg;

    overflow: hidden;
  }
  .imgAutor {
    position: absolute;
    top: -23%;
    left: -15%;
    bottom: -20%;
    right: -15%;
    width: 107.994px;
    height: 118.794px;
    rotate: -45deg;
  }
  .autorName {
    color: #3e51c1;
    font-size: 24px;
    font-family: Ubuntu;
    font-weight: 700;
    line-height: 121%;
    margin: 0;
  }
  .blockAutorWrap {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    justify-content: flex-end;
  }
  .infoAutor {
    width: 60%;

    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .autorYelowBlock {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 140%;
    right: 50%;
    height: auto;
    background: #f7cb25;
  }
  .hvist {
    position: absolute;

    width: 105.5px;
    height: 45.33px;
    top: -1%;
    left: -30px;
    z-index: -1;

    clip-path: polygon(50% 0%, 0 100%, 100% 100%);
    background: #f7cb25;
    rotate: 90deg;
  }
  .productAutorWrapSM {
    width: 30%;
    flex-shrink: 0;
    margin-right: 20px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
  .picSmallProdAutor {
    width: 100%;
    height: auto;
  }
  .smallProdIcon {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .allProdactAutor {
    position: absolute;
    left: calc(50% - 113.5px);
    bottom: -100px;

    width: 227px;
    height: 60px;
    background: #ff3416;
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 117.5%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    color: #ffffff;
  }
  .autorListSmal {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .imgAutorWrapSmall {
    position: relative;

    width: 126.57px;
    height: 126.57px;
    rotate: 0deg;
    margin-bottom: 90px;
  }
  .publishListWrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    width: 90%;
  }
  .blogList {
    width: 100%;
    height: auto;
    background: #3e51c1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding-bottom: 72px;
  }
  .mainPageBlogH2 {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 111%;
    color: #3e51c1;
    margin-left: 14px;
  }
  .blogProdPage {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    margin-top: 20px;
    margin-bottom: 40px;
  }
  .vidoPic {
    width: calc(100%- 28px);
    height: calc(100% - 28px);
    border: 14px solid #f7cb25;
  }
  .videoViewAgeWrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding-top: 68px;
    padding-bottom: 89px;
  }
  .vidoViewBlockVideo {
    position: relative;
    margin-left: 0px;
    border: 20px solid #3fac8c;
    width: calc(100% - 40px);
    height: calc(454px - 40px);
  }
  .zaVikomOpus {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: calc(100% - 10px);
    margin-left: 10px;
    height: 100%;
    justify-content: space-between;
  }
  .zaVikomOpusButton {
    align-self: center;
  }
  .subscribeWrap {
    width: 100%;
    height: auto;
    background: #3e51c1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 68px;
  }
  .logSubscribe {
    margin-bottom: 0px;
  }
  .subscribeButton {
    margin-left: 0;
  }
}
@media only screen and (max-width: 1291px) and (min-width: 1100px) {
  .descriptionBooksNew {
    width: 99%;
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 130%;
    color: #000000;
  }
  .largeIcon {
    width: 500px;
    height: 512.83px;
  }
}
@media only screen and (max-width: 1369px) and (min-width: 1291px) {
  .bannerP {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 600;
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 28px;
    color: #3e3092;
  }
  .largeIcon {
    width: 700px;
    height: 512.83px;
  }
  .descriptionBooksNew {
    width: 99%;
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 130%;
    color: #000000;
  }
}
@media only screen and (max-width: 1500px) and (min-width: 1369px) {
  .descriptionBooksNew {
    width: 99%;
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 130%;
    color: #000000;
  }
  .largeIcon {
    width: 700px;
    height: 512.83px;
  }
  .bannerP {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 600;
    margin-bottom: 15px;
    font-size: 20px;
    line-height: 28px;
    color: #3e3092;
  }
}
@media only screen and (max-width: 1600px) and (min-width: 1500px) {
  .bannerP {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 600;
    margin-bottom: 15px;
    font-size: 20px;
    line-height: 28px;
    color: #3e3092;
  }
}
