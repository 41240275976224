.bleuLabel {
  width: 100%;
  height: 116px;
  background: #3e51c1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.blueLabelWnutr {
  width: 90%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.firstTextInBlock {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 149%;
  /* or 36px */
  margin: 0;

  color: #ffffff;
}
.userInformationWrap {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.userInformationWrapSmall {
  width: 95%;
  height: auto;
  display: flex;

  align-items: center;
  justify-content: space-between;
}
.yourInformation {
  width: 48%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 10px;
}
.outButton {
  color: #3e51c1;
  font-family: Ubuntu;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 141.5%;
  text-decoration-line: underline;
  margin: 0;
  margin-top: 5px;
  cursor: pointer;
}
.ourInformation {
  width: 50%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: flex-start;
  margin-top: 60px;
}
.userData {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.imgAutorWrapSmall {
  position: relative;

  width: 126.57px;
  height: 126.57px;
  rotate: 0deg;
}
.imgAutorSmall {
  position: absolute;
  width: 126.57px;
  height: 126.57px;
  object-fit: cover;
  /* clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%); */
}
.nameAndPropWr {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 20px;
}
.userNameHello {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 111%;
  /* or 40px */
  margin: 0;
  margin-top: 30px;
  color: #3fac8c;
}
.propP {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 141.5%;
  /* or 34px */
  margin: 0;
  margin-left: 7px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.4);
}
.propWrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}
.newdso {
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.iconPicBird {
}
.yourRegistration {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 141.5%;
  /* or 34px */
  margin: 0;
  text-transform: uppercase;

  color: #3e51c1;
}
.yourDescription {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 141.5%;
  /* or 34px */
  margin: 0;
  margin-top: 5px;

  color: #000000;
}
.socialWrap {
  width: 50%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
}
.standartsocial {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 62.85px;
  height: 62.85px;
  border-radius: 50%;
  background: #f7cb25;
  cursor: pointer;
}
.orderingProductWrap {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
.orderingProductWrapSmall {
  width: 90%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.block {
  width: auto;
  padding: 8px;
  padding-left: 10px;
  padding-right: 10px;
  height: calc(54px - 6px);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid #f7cb25;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 111%;
  /* identical to box height, or 38px */
  cursor: pointer;
  text-align: center;

  color: #3e51c1;
}
.selected {
  width: auto;
  padding: 8px;
  padding-left: 10px;
  padding-right: 10px;
  height: calc(54px - 6px);
  background: #f7cb25;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.wrapLab {
  width: 78%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.waitOrderInPostWrap {
  width: 90%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.prodInWrap {
  width: 21%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 50px;
  margin-top: 44px;
}
.picProd {
  width: 272px;
  height: 357px;
}
.bookName {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 111%;

  color: #3e3092;
}
.deliveryInform {
  width: 100%;
  min-height: 56px;
  background: #3e51c1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 117.5%;
  display: flex;
  align-items: center;
  text-align: center;
  cursor: pointer;
  color: #ffffff;
}
.yourDiscountWrap {
  width: 100%;
  height: 187px;
  background: #3fac8c;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 82px;
}
.yourDiscountWrapInfo {
  width: 100%;
  height: auto;
  background: #3fac8c;
  display: flex;
  align-items: center;
  justify-content: center;
}
.yourDiscountWrapSmall {
  width: 70%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dicsTextWr {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 33%;
}
.dicsTextWrInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 70%;
}
.yourDicrFive {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 34px;
  line-height: 111%;
  /* or 38px */
  margin-top: 0;
  margin-bottom: 0;
  color: #ffffff;
}
.yourDicrFiveSpan {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 111%;
  /* or 38px */

  color: #ffffff;
}
.yourDicrFiveSmall {
  font-family: "Ubuntu";
  font-style: italic;
  font-weight: 300;
  font-size: 18px;
  line-height: 111%;
  width: 100%;
  margin-top: 20px;

  color: #ffffff;
}
.buttonConfFRW {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
}
.confirmP {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 100%;
  /* or 38px */
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  color: #ffffff;
}
.inputTgId {
  width: 227px;
  height: 60px;
  border: none;
  margin-top: 10px;
  margin-bottom: 10px;
}
.likeButton {
  width: 227px;
  height: 60px;
  background: #f7cb25;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 117.5%;
  /* or 21px */
  text-decoration: none;
  display: flex;
  align-items: center;
  text-align: center;

  color: #3e3092;
  cursor: pointer;
}
.likeButtonChange {
  width: 227px;
  height: 60px;
  background: #f7cb25;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 117.5%;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  text-align: center;
  border: none;
  margin-top: 30px;
  color: #3e3092;
}
.elefantWrap {
  width: 100%;
  height: 410px;
  background: #d6cfff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.elefantWrapSmall {
  width: 60%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.elef {
  width: 50%;
  height: auto;
  position: relative;
}
.elefant {
  width: 100%;
  height: auto;
}
.wrapInput {
  position: relative;
}
.inputId {
  width: 250px;
  height: 35px;
  border-radius: 10px;
  border: none;
  padding-left: 10px;
}
.dropdownItem {
  color: #000;

  font-family: Ubuntu;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 152.5%;
  margin: 0;
  margin-bottom: 10px;
  cursor: pointer;
}
.sectionTitle {
  font-family: Ubuntu;
  font-size: 18px;
  color: white;
}
.dropdownList {
  position: absolute;
  top: 60px;
  left: 0;
  color: black;

  max-height: 600px;
  padding-top: 20px;
  padding-bottom: 20px;
  overflow: auto;
  list-style-type: none;
  background-color: white;
  z-index: 2;
  width: calc(99% - 40px);
  box-shadow: 0 0 10px 2px rgba(5, 173, 61, 0.6);
  border-radius: 0 0 5px 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 5px;
}
.howMathElefant {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 34px;
  line-height: 111%;
  /* or 38px */

  color: #ffffff;
  position: absolute;
  top: 7%;
  left: 3%;
}
.howMathElefantSpan {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 111%;
  /* or 38px */

  color: #ffffff;
}
.elefantDescription {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 34px;
  line-height: 111%;
  /* or 38px */
  width: 42%;
  margin-left: 10px;
  color: #3e51c1;
  align-self: flex-start;
}
.elefantDescriptionSpan {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 34px;
  line-height: 111%;
  /* or 38px */

  color: #3e3092;
}
.readerUserWrap {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.readerUserWrapSmall {
  width: 90%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.aboutCompanyH1 {
  color: #3e3092;
  font-size: 34px;
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 700;
  line-height: 111%;
  margin-bottom: 0;
  text-decoration: none;
  cursor: pointer;
}
.paragInWrapBig {
  color: #000;
  font-size: 24px;
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 500;
  line-height: 168%;
}
.pdfButton {
  color: white;
  background: #3e3092;
  width: 200px;
  height: 50px;
  font-size: 20px;
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 500;
  line-height: 168%;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.changeDataWrap {
  position: fixed;
  width: 70%;
  border-radius: 30px;
  height: auto;
  padding-bottom: 30px;
  background: #3e51c1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  z-index: 100;
}
.countSvg {
  margin-right: 15px;
  margin-top: 18px;
  cursor: pointer;
  display: flex;
  justify-self: flex-end;
}
.changeDataWrapSmall {
  width: 95%;
  height: 95%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.nameCountWrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.countH2 {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 34px;
  line-height: 111%;
  color: white;
}
.inputWrapCard {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.inputChangeName {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 111%;
  color: white;
}
.spanLinfOpt {
  cursor: pointer;
}
.inputTypesK {
  color: white;
}
@media only screen and (max-width: 599px) and (min-width: 300px) {
  .changeDataWrap {
    position: fixed;
    width: 100%;
    left: 0;
    border-radius: 30px;
    height: auto;
    padding-bottom: 30px;
    background: #3e51c1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .changeDataWrapSmall {
    width: 95%;
    height: 95%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .bleuLabel {
    width: 100%;
    height: auto;
    background: #3e51c1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .firstTextInBlock {
    color: #fff;
    font-size: 16px;
    font-family: Ubuntu;
    font-weight: 500;
    line-height: 149%;
    /* or 36px */
    margin: 0;
  }
  .ourInformation {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: flex-start;
    margin-top: 60px;
  }
  .userData {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .imgAutorWrapSmall {
    position: relative;
    width: 96.167px;
    height: 96.167px;
    rotate: 0deg;
  }
  .imgAutorSmall {
    position: absolute;
    width: 96.167px;
    height: 96.167px;
    /* clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%); */
  }
  .userNameHello {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 111%;
    /* or 40px */
    margin: 0;
    margin-top: 30px;
    color: #3fac8c;
  }
  .propP {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 141.5%;
    /* or 34px */
    margin: 0;
    margin-left: 7px;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.4);
  }
  .iconPicBird {
    align-self: center;
  }
  .yourInformation {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
  }
  .yourRegistration {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 141.5%;
    /* or 34px */
    margin: 0;
    text-transform: uppercase;
    width: 100%;
    color: #3e51c1;
  }
  .yourDescription {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 141.5%;
    /* or 34px */
    margin: 0;
    margin-top: 5px;

    color: #000000;
  }
  .socialWrap {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
  }
  .wrapLab {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
  }
  .orderingProductWrapSmall {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .block {
    padding: 8px;
    padding-left: 10px;
    padding-right: 10px;
    width: 254px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid #f7cb25;
    color: #3e51c1;
    text-align: center;
    font-size: 18px;
    font-family: Ubuntu;
    font-weight: 700;
    line-height: 111%;
    margin-top: 10px;
    /* identical to box height, or 38px */

    text-align: center;

    color: #3e51c1;
  }
  .waitOrderInPostWrap {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;

    flex-wrap: nowrap;
    overflow: hidden;
    overflow-x: scroll;

    white-space: nowrap;
  }
  .prodInWrap {
    width: 272px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 50px;
    margin-top: 44px;
    margin-left: 50px;
  }
  .yourDiscountWrap {
    width: 100%;
    height: auto;
    background: #3fac8c;
    display: flex;

    align-items: center;
    justify-content: center;
    margin-top: 82px;
  }
  .yourDiscountWrapSmall {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .dicsTextWr {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 80%;
  }
  .buttonConfFRW {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
  }
  .elefantWrap {
    width: 100%;
    height: auto;
    background: #d6cfff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .elefantWrapSmall {
    padding-top: 20px;
    padding-bottom: 20px;
    width: 90%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .howMathElefant {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 111%;
    /* or 38px */

    color: #ffffff;
    position: absolute;
    top: 7%;
    left: 3%;
  }
  .howMathElefantSpan {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 111%;
    /* or 38px */

    color: #ffffff;
  }
  .elefantDescription {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 34px;
    line-height: 111%;
    /* or 38px */
    width: 90%;

    color: #3e51c1;
    align-self: flex-start;
  }
  .userInformationWrapSmall {
    width: 95%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
}
@media only screen and (max-width: 700px) and (min-width: 599px) {
  .changeDataWrap {
    position: fixed;
    width: 100%;
    left: 0;
    border-radius: 30px;
    height: auto;
    padding-bottom: 30px;
    background: #3e51c1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .bleuLabel {
    width: 100%;
    height: auto;
    background: #3e51c1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .firstTextInBlock {
    color: #fff;
    font-size: 16px;
    font-family: Ubuntu;
    font-weight: 500;
    line-height: 149%;
    /* or 36px */
    margin: 0;
  }
  .ourInformation {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: flex-start;
    margin-top: 60px;
  }
  .userData {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .imgAutorWrapSmall {
    position: relative;
    width: 96.167px;
    height: 96.167px;
    rotate: 0deg;
  }
  .imgAutorSmall {
    position: absolute;
    width: 96.167px;
    height: 96.167px;
    /* clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%); */
  }
  .userNameHello {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 111%;
    /* or 40px */
    margin: 0;
    margin-top: 30px;
    color: #3fac8c;
  }
  .propP {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 141.5%;
    /* or 34px */
    margin: 0;
    margin-left: 7px;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.4);
  }
  .iconPicBird {
    align-self: center;
  }
  .yourInformation {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
  }
  .yourRegistration {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 141.5%;
    /* or 34px */
    margin: 0;
    text-transform: uppercase;
    width: 100%;
    color: #3e51c1;
  }
  .yourDescription {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 141.5%;
    /* or 34px */
    margin: 0;
    margin-top: 5px;

    color: #000000;
  }
  .socialWrap {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
  }
  .wrapLab {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
  }
  .orderingProductWrapSmall {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .block {
    padding: 8px;
    padding-left: 10px;
    padding-right: 10px;
    width: 254px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid #f7cb25;
    color: #3e51c1;
    text-align: center;
    font-size: 18px;
    font-family: Ubuntu;
    font-weight: 700;
    line-height: 111%;
    margin-top: 10px;
    /* identical to box height, or 38px */

    text-align: center;

    color: #3e51c1;
  }
  .waitOrderInPostWrap {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;

    flex-wrap: nowrap;
    overflow: hidden;
    overflow-x: scroll;

    white-space: nowrap;
  }
  .prodInWrap {
    width: 272px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 50px;
    margin-top: 44px;
    margin-left: 50px;
  }
  .yourDiscountWrap {
    width: 100%;
    height: auto;
    background: #3fac8c;
    display: flex;

    align-items: center;
    justify-content: center;
    margin-top: 82px;
  }
  .yourDiscountWrapSmall {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .dicsTextWr {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 80%;
  }
  .buttonConfFRW {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
  }
  .elefantWrap {
    width: 100%;
    height: auto;
    background: #d6cfff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .elefantWrapSmall {
    padding-top: 20px;
    padding-bottom: 20px;
    width: 90%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .howMathElefant {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 111%;
    /* or 38px */

    color: #ffffff;
    position: absolute;
    top: 7%;
    left: 3%;
  }
  .howMathElefantSpan {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 111%;
    /* or 38px */

    color: #ffffff;
  }
  .elefantDescription {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 34px;
    line-height: 111%;
    /* or 38px */
    width: 90%;

    color: #3e51c1;
    align-self: flex-start;
  }
  .userInformationWrapSmall {
    width: 95%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
}
@media only screen and (max-width: 1288px) and (min-width: 1024px) {
  .changeDataWrap {
    position: fixed;
    width: 100%;
    left: 0;
    border-radius: 30px;
    height: auto;
    padding-bottom: 30px;
    background: #3e51c1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media only screen and (max-width: 1024px) and (min-width: 701px) {
  .changeDataWrap {
    position: fixed;
    width: 100%;
    left: 0;
    border-radius: 30px;
    height: auto;
    padding-bottom: 30px;
    background: #3e51c1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .bleuLabel {
    width: 100%;
    height: auto;
    background: #3e51c1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .firstTextInBlock {
    color: #fff;
    font-size: 16px;
    font-family: Ubuntu;
    font-weight: 500;
    line-height: 149%;
    /* or 36px */
    margin: 0;
  }
  .ourInformation {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: flex-start;
    margin-top: 60px;
  }
  .userData {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .imgAutorWrapSmall {
    position: relative;
    width: 96.167px;
    height: 96.167px;
    rotate: 0deg;
  }
  .imgAutorSmall {
    position: absolute;
    width: 96.167px;
    height: 96.167px;
    /* clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%); */
  }
  .userNameHello {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 111%;
    /* or 40px */
    margin: 0;
    margin-top: 30px;
    color: #3fac8c;
  }
  .propP {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 141.5%;
    /* or 34px */
    margin: 0;
    margin-left: 7px;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.4);
  }
  .iconPicBird {
    align-self: center;
  }
  .yourInformation {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
  }
  .yourRegistration {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 141.5%;
    /* or 34px */
    margin: 0;
    text-transform: uppercase;
    width: 100%;
    color: #3e51c1;
  }
  .yourDescription {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 141.5%;
    /* or 34px */
    margin: 0;
    margin-top: 5px;

    color: #000000;
  }
  .socialWrap {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
  }
  .wrapLab {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
  }
  .orderingProductWrapSmall {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .block {
    padding: 8px;
    padding-left: 10px;
    padding-right: 10px;
    width: 254px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid #f7cb25;
    color: #3e51c1;
    text-align: center;
    font-size: 18px;
    font-family: Ubuntu;
    font-weight: 700;
    line-height: 111%;
    margin-top: 10px;
    /* identical to box height, or 38px */

    text-align: center;

    color: #3e51c1;
  }
  .waitOrderInPostWrap {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;

    flex-wrap: nowrap;
    overflow: hidden;
    overflow-x: scroll;

    white-space: nowrap;
  }
  .prodInWrap {
    width: 272px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 50px;
    margin-top: 44px;
    margin-left: 50px;
  }
  .yourDiscountWrap {
    width: 100%;
    height: auto;
    background: #3fac8c;
    display: flex;

    align-items: center;
    justify-content: center;
    margin-top: 82px;
  }
  .yourDiscountWrapSmall {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .dicsTextWr {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 80%;
  }
  .buttonConfFRW {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
  }
  .elefantWrap {
    width: 100%;
    height: auto;
    background: #d6cfff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .elefantWrapSmall {
    padding-top: 20px;
    padding-bottom: 20px;
    width: 90%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .howMathElefant {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 111%;
    /* or 38px */

    color: #ffffff;
    position: absolute;
    top: 7%;
    left: 3%;
  }
  .howMathElefantSpan {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 111%;
    /* or 38px */

    color: #ffffff;
  }
  .elefantDescription {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 34px;
    line-height: 111%;
    /* or 38px */
    width: 90%;

    color: #3e51c1;
    align-self: flex-start;
  }
  .userInformationWrapSmall {
    width: 95%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
}
