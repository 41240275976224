.blueHeaderOrder {
  width: 100%;
  height: 116px;
  background: #3e51c1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.blueHeaderOrderEnter {
  width: 90%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.titleH3OrderEnter {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 111%;
  color: #ffffff;
}
.wrapCenterOrder {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wrapCenterOrderSmall {
  width: 90%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.firstBlockWrap {
  width: 60%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 26px;
}
.newUserOrNoWrap {
  width: 80%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.newBayer {
  width: 235px;
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f7cb25;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 111%;
  text-align: center;
  cursor: pointer;

  color: #3e51c1;
}
.newAlwaysBayer {
  width: 281px;
  height: calc(43px - 6px);
  border: 3px solid #f7cb25;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 111%;
  text-align: center;
  cursor: pointer;

  color: #3e51c1;
}
.labelMi {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #3fac8c;
}
.wrapUserData {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  height: auto;
}
.inputSmall {
  width: calc(46% - 6px);
  height: calc(56px - 6px);
  padding-top: 0;
  padding-bottom: 0;
  background: #ffffff;
  border: 3px solid #3fac8c;
  padding-left: 15px;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  color: black;
}
.inputSmallWrap {
  width: calc(93%);
  height: calc(56px - 6px);
  padding-top: 0;
  padding-bottom: 0;
  background: #ffffff;
  border: 3px solid #3fac8c;
  padding-left: 15px;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  color: #758391;
}
.inputBig {
  margin-top: 25px;
  width: calc(100% - 6px);
  height: 56px;
  background: #ffffff;
  border: 3px solid #3fac8c;
  padding-left: 15px;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  color: black;
}
.deliveryChoisWrap {
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: flex-start;
}
.choisWrap {
  display: flex;
  align-items: center;
  width: auto;
  height: auto;
  margin-right: 22px;
}
.choisWi {
  width: calc(30px - 6px);
  height: calc(30px - 6px);
  border: 3px solid #3fac8c;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wi {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #ff3416;
}
.choisP {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #3fac8c;
  margin-left: 11px;
  cursor: pointer;
}

.chousBooksSelect {
  position: relative;
  width: calc(46% + 17px);
  height: auto;
}
.chousBooksSelectBig {
  margin-top: 34px;
  position: relative;
  width: calc(100% + 17px);
  height: auto;
}
.customSelect {
  cursor: pointer;
  border: 3px solid #3fac8c;
  width: calc(100%);
  height: 56px;
  appearance: none;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-left: 15px;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;

  color: #758391;
}
.customSelectInput {
  cursor: pointer;
  border: 3px solid #3fac8c;
  width: calc(100% - 23px);
  height: 56px;
  appearance: none;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-left: 15px;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;

  color: #758391;
}
.customOpin:checked {
  background: #3fac8c;
  color: #c4c4c4;
}
.customArrowSelect {
  position: absolute;
  top: 25px;
  width: 19px;
  height: 9px;
  right: 30px;
  pointer-events: none;
}
.customSelect option {
  color: #c4c4c4;
}
.orderConfirmation {
  width: 273px;
  height: 60px;
  background: #3fac8c;
  border: none;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 117.5%;
  color: #ffffff;
  align-self: center;
  margin-top: 38px;
  cursor: pointer;
  margin-bottom: 38px;
}
.ulWrapBigCity {
  position: absolute;
  top: 40px;
  left: 0;
  color: black;

  max-height: 600px;
  padding-top: 20px;
  padding-bottom: 20px;
  overflow: auto;
  list-style-type: none;
  background-color: white;
  z-index: 2;
  width: calc(99% - 40px);
  box-shadow: 0 0 10px 2px rgba(5, 173, 61, 0.6);
  border-radius: 0 0 5px 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.liInListSitu {
  color: #000;

  font-family: Ubuntu;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 152.5%;
  margin: 0;
  margin-bottom: 10px;
  cursor: pointer;
}
.ulWrapBigViddilena {
  position: absolute;
  top: 40px;
  left: 0;
  color: black;
  width: 95%;
  max-height: 600px;
  padding-top: 20px;
  padding-bottom: 20px;
  overflow: auto;
  list-style-type: none;
  background-color: white;
  z-index: 2;
  width: calc(99% - 40px);
  box-shadow: 0 0 10px 2px rgba(5, 173, 61, 0.6);
  border-radius: 0 0 5px 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.wrapProdForOrder {
  margin-top: 53px;
  width: 37%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  align-self: flex-start;
}
.lineWrap {
  width: 100%;
  height: 3px;
  background: #3fac8c;
}

.prodInCardWrap {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  justify-self: flex-end;
}
.prodInfoWrap {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 55%;

  height: auto;
}
.booksNameCard {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 111%;
  color: #3e51c1;
}
.chousLangSelect {
  position: relative;
}
.customSelectInCard {
  cursor: pointer;
  border: 3px solid #3fac8c;
  width: 255px;
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  padding-left: 14px;
  appearance: none;
  line-height: 111%;
  color: #3fac8c;
  margin-bottom: 21px;
}
.arrowDown {
  position: absolute;
  top: 16px;
  cursor: pointer;
  left: 225px;
  width: 19px;
  height: 9px;
}
.counterWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 255px;
}
.countPlusMinusValue {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.minus {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 34px;
  background: #ffffff;
  border: 1px solid #ff3416;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 117.5%;
  color: #3e3092;
  margin-right: 14px;
}
.registerToOrder {
  color: #3e3092;
  font-weight: 500;
  font-size: 20px;
  line-height: 117.5%;
}
.registerToOrderSpan {
  color: #3e3092;
  font-weight: 700;
  font-size: 20px;
  line-height: 117.5%;
  cursor: pointer;
}
.countValue {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 117.5%;
  color: #ff3416;
  margin-right: 14px;
}
.plus {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 34px;
  background: #ffffff;
  border: 1px solid #ff3416;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 117.5%;
  color: #3e3092;
}
.delete {
  position: absolute;
  right: 13%;
  cursor: pointer;
  top: 10%;
}
.bookFoto {
  width: 172px;
  height: 197px;
  margin-right: 10px;
}
.price {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 111%;
  /* or 38px */

  display: flex;
  align-items: center;
  text-align: center;

  color: #3e51c1;
}
.priceSpan {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 152.5%;
  /* or 21px */

  text-align: center;

  color: #3e51c1;
}
.spinerWrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loadingLogo {
  width: 250px;
  height: 250px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.descBookInOrder {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.blockProperty {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 126px;
  height: 41px;
  background: #f7cb25;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 111%;
  /* or 18px */

  text-align: center;

  color: #3e3092;
}
.sumOrder {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 111%;
  color: #3e51c1;
  margin-bottom: 0;
}
.slonuInL {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 111%;
  color: #3e51c1;
}
.discountWrapOrder {
  width: 100%;
  margin-top: 57px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.promoInput {
  width: calc(67% - 6px - 30px);
  height: calc(56px - 8px);
  background: #ffffff;
  border: 3px solid #3fac8c;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  padding-left: 30px;

  color: #c4c4c4;
}
.promoButton {
  width: 30%;
  height: 56px;
  background: #3fac8c;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 117.5%;
  cursor: pointer;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;
}
.sum {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 111%;
  /* or 40px */

  color: #3e51c1;
}
@media only screen and (max-width: 600px) and (min-width: 300px) {
  .wrapProdForOrder {
    margin-top: 53px;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    align-self: flex-start;
  }
  .wrapCenterOrderSmall {
    width: calc(100% - 20px);
    height: auto;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
  }
  .prodInCardWrap {
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 30px;
    justify-self: flex-end;
  }
  .bookFoto {
    width: 133.336px;
    height: 152.384px;
    flex-shrink: 0;
  }
  .prodInfoWrap {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 60%;

    height: auto;
  }
  .firstBlockWrap {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 26px;
  }
  .newUserOrNoWrap {
    width: 80%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .newAlwaysBayer {
    width: 200px;
    height: calc(52px);
    border: 3px solid #f7cb25;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 111%;
    text-align: center;
    align-self: center;
    color: #3e51c1;
    margin-top: 10px;
  }
  .inputSmall {
    margin-top: 15px;
    width: calc(100% - 6px);
    height: calc(56px - 6px);
    padding-top: 0;
    padding-bottom: 0;
    background: #ffffff;
    border: 3px solid #3fac8c;
    padding-left: 15px;
    font-family: "Raleway";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    color: #c4c4c4;
  }
  .chousBooksSelect {
    position: relative;
    width: calc(100% + 17px);
    height: auto;
  }
  .inputSmallWrap {
    width: calc(95%);
    height: calc(56px - 6px);
    padding-top: 0;
    padding-bottom: 0;
    background: #ffffff;
    border: 3px solid #3fac8c;
    padding-left: 15px;
    font-family: "Raleway";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    color: #758391;
    margin-top: 5px;
  }
}
@media only screen and (max-width: 700px) and (min-width: 479px) {
}
@media only screen and (max-width: 1288px) and (min-width: 1024px) {
}
@media only screen and (max-width: 1024px) and (min-width: 701px) {
}
