.wrap {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #3e3092;
}
.pfor {
  color: #f7cb25;

  text-align: center;
  font-family: Ubuntu;
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: 111%;
}
.linkGFi {
  display: flex;
  width: 298px;
  height: 58px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  background: #f7cb25;
  color: #3e51c1;

  text-align: center;
  font-family: Ubuntu;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 117.5%;
  text-decoration: none;
}
