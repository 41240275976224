.wrapButtonCountList {
  display: flex;
  gap: 8px;
  justify-content: center;
  margin-top: 20px;
}

.buttonWithCountPage {
  width: 50px;
  height: 50px;
  background: #f7cb25;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 152.5%;
  margin-left: 20px;
  margin-top: 50px;
  margin-bottom: 50px;
  color: #3e51c1;
}

.activeButton {
  background-color: red !important;
  color: white;
  border: 1px solid red;
}
