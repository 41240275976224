.WrapAdm {
  width: 80%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.fullAdmWrapp {
  display: flex;
  width: 100%;
  height: auto;
}
.quillContainer {
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 5px;
  height: 500px;
  width: 600px;
}
.editorContainer {
  margin-top: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  width: 600px;
}

.quillEditor {
  height: 600px; /* Задайте потрібну висоту редактора */
  max-height: 600px; /* Обмежуємо максимальну висоту */
  overflow-y: auto; /* Додаємо прокрутку для вертикалі */
  margin-top: 30px;
  margin-bottom: 30px;
}

.ql-editor {
  min-height: 350px; /* Внутрішній редактор також отримає мінімальну висоту */
  padding: 10px; /* Відступи для зручного відображення тексту */
  box-sizing: border-box;
}
.admList {
  width: 20%;
  height: auto;
  position: static;
  top: 0;
  left: 0;
  background: #3e3092;
}
.listUlForAdm {
  padding: 0;
}
.listUlForAdmLi {
  color: #fff;
  font-size: 24px;
  font-family: Ubuntu;
  font-weight: 500;
  width: 100%;
  line-height: 120%;
  border-bottom: 1px solid #fff;
  list-style-type: none;
  height: 60px;
}
.listUlForAdmLiA {
  text-decoration: none;
  color: #fff;
  font-size: 24px;
  font-family: Ubuntu;
  font-weight: 500;
  width: 100%;
  line-height: 120%;

  display: flex;
  align-items: center;
  justify-content: center;

  list-style-type: none;
  height: 60px;
}
.formAddBook {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.wrapSmallList {
  width: 30%;
}
.paramBooks {
  color: #3e3092;
  font-size: 18px;
  font-family: Ubuntu;
  font-weight: 500;
  line-height: 120%;
}
.inputBooks {
  width: calc(100% - 4px);
  height: 40px;
  border: 2px solid #3e3092;
  border-radius: 0;
}
.addBooksAdmH1 {
  color: #3e3092;
  font-size: 36px;
  font-family: Ubuntu;
  font-weight: 300;
  line-height: 120%;
}
.addBookButton {
  width: 227px;
  height: 60px;
  border: none;
  background: #3e3092;
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-family: Ubuntu;
  font-weight: 500;
  line-height: 117.5%;
}
.listUlForAdmLiAA {
  color: #f7cb25;
}
.productList {
  width: 80%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.productListHeading {
  color: #3e3092;
  font-size: 36px;
  font-family: Ubuntu;
  font-weight: 300;
  line-height: 120%;
}
.productItem {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.smalDescBookWr {
  width: calc(100% - 10px - 2px);
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #3e3092;
  padding-left: 5px;
  padding-right: 5px;
}
.bookName {
  color: #3e51c1;
  font-size: 24px;
  font-family: Ubuntu;
  font-weight: 700;
  line-height: 111%;
}
.ProductDetails {
  width: calc(100% - 4px - 40px);
  height: auto;
  padding: 20px;
  border: 2px solid #f7cb25;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.Field {
  width: 30%;
  border: 2px solid #3e51c1;
}
.delProd {
  border: none;
  width: 200px;
  height: 30px;
  background: #3e51c1;
  color: #fff;
  font-size: 15px;
  font-family: Ubuntu;
  font-weight: 300;
  line-height: 120%;
}
.orderListAllWrap {
  width: 80%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.wrapAllAll {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}
.orderWrapListinAdm {
  width: calc(100% - 20px - 2px);
  padding-left: 10px;
  padding-right: 10px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #3e51c1;
}
.prodDescWrap {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pOrderParag {
  font-size: 20px;
  font-family: Ubuntu;
}
.blogWrap {
  width: 80%;
  height: auto;
  display: flex;

  justify-content: center;
}
.addBookButtonBlog {
  width: 180px;
  height: 40px;
  margin-top: 20px;
  border: none;
  background: #3e3092;
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-family: Ubuntu;
  font-weight: 500;
  line-height: 117.5%;
}
.faromToPromo {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.promoWrapOne {
  width: 90%;
}
.promoWrapTwo {
  width: 90%;
}
.labelPromo {
  font-size: 20px;
  font-family: Ubuntu;
}
.promoInput {
  margin-left: 5px;
  margin-right: 20px;
  width: 40%;
}
.promoBut {
  border: none;
  background: #3e3092;
  color: #fff;
  font-size: 16px;
  font-family: Ubuntu;
}
.userListWrapUL {
  width: 70%;
  height: auto;
}
.userListWrapLi {
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 20px;
  border: 1px solid #3e3092;
}
.userListWrapP {
  display: flex;
  flex-direction: column;
  margin: 0;
}
.changeFotoWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
}
.buttonWrap {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.ulHer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding-left: 0;
}
.wrapAllPHL {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}
.wrapHeroF {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.listLiProd {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #3e3092;
}
.divForList {
  width: 80%;
  height: auto;
}
.imgInWr {
  width: 400px;
  height: auto;
}
.WrapAdm {
  width: 80%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.liWrapPic {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.redButAdm {
  margin-left: 15px;
  margin-right: 15px;
}
.wrapNameInHero {
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.currentImg {
  width: 300px;
  height: auto;
}
.resultsWrap {
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.bookBlock {
  margin-left: 30px;
}
.booKsRationf {
  width: 300px;
  height: auto;
}
.wrapAdmLisPage {
  padding: 20px;
  width: calc(80% - 40px);
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.mainPageButtonO {
  font-size: 24px;
  font-family: Ubuntu;
  color: white;
  cursor: pointer;
  padding-left: 20px;
  padding-right: 20px;
  background: #3e51c1;
  padding-top: 10px;
  padding-bottom: 10px;
}
.buttonWrapInPage {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 30px;
}
.wrapTeatAreaOpicun {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.textarea {
  width: 400px;
  height: 100px;
  margin-top: 20px;
}
.quilForAutorAnd {
  height: 400px;
  margin-bottom: 50px;
}
