.titleWrap {
  width: 100%;
  min-height: 703px;
  background: #d6cfff;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.h3Title {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 111%;
  /* or 38px */
  margin-bottom: 0;
  text-align: center;

  color: #3e3092;
}
.birdWrapFirst {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 70%;
  height: auto;
  margin-left: 30px;
}
.birdWrapSecond {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 70%;
  height: auto;
  margin-right: 50px;
}
.birdWrap {
  width: 30%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.birdPDesc {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 111%;
  /* or 20px */
  width: 100%;
  text-align: center;

  color: #3e3092;
}
.arrowBirdOptSt {
  margin-top: 70px;
  align-self: flex-start;
}
.blockTextWrap {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 34px;
  padding-bottom: 34px;
}
.blockTextWrapSmall {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.pBlockTextWrap {
  margin: 0;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 124%;
  /* or 30px */

  color: #000000;
}
.timeToChas {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 111%;
  /* or 38px */
  margin: 0;

  color: #3e3092;
}
.salesBlockWrap {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 49px;
}
.blueBlockWrap {
  width: 49%;
  background: #3e3092;
  height: 454px;
  position: relative;
}
.saleForNewYear {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 111%;
  color: #ffffff;
  position: absolute;
  left: 8%;
}

.prdeProdPricingCart {
  cursor: pointer;
  width: 120.21px;
  height: 120.21px;
  color: white;
  background: #f7cb25;
  transform: rotate(45deg);
  font-family: "Ubuntu";
  display: flex;
  margin-left: 20px;
  margin-top: 5px;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 35%;
  left: 10%;
}
.prdeProdPricingRotateCart {
  display: flex;
  flex-direction: column;
  align-items: center;

  transform: rotate(-45deg);
}
.salePriceCart {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 54px;
  line-height: 111%;
  color: #3e3092;
  margin: 0;
}
.fullPriceCart {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 152.5%;
  /* or 27px */

  text-align: center;
  margin: 0;
  margin-bottom: 30px;
  color: #3e3092;
}
.buttonJoin {
  border: none;
  width: 194px;
  height: 60px;
  background: #3fac8c;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 117.5%;
  color: #ffffff;
  position: absolute;
  top: 80%;
  left: 8%;
  cursor: pointer;
}
.buttonJoinPr {
  border: none;
  width: 194px;
  height: 60px;
  background: #3e3092;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 117.5%;
  color: #ffffff;
  position: absolute;
  top: 80%;
  left: 8%;
  cursor: pointer;
}
.saleNewOneSt {
  width: 182px;
  height: 163px;
  position: absolute;
  left: 45%;
  top: 10%;
}
.saleNewTwoSt {
  width: 179px;
  height: 238px;
  position: absolute;
  left: 41%;
  top: 44%;
}
.saleThreBlockSt {
  position: absolute;
  width: 186px;
  height: 244px;
  left: 71%;
  top: 22%;
}
.vidTwPcs {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 111%;
  /* or 27px */

  text-align: right;

  color: #ffffff;
  position: absolute;
  top: 80%;
  left: 71%;
}
.vidTwPcsPr {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 111%;
  /* or 27px */

  text-align: right;

  color: #3e3092;
  position: absolute;
  top: 80%;
  left: 71%;
}
.yelowBlockWrap {
  width: 49%;
  background: #f7cb25;
  height: 454px;
  position: relative;
}
.saleForProduction {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 111%;
  /* or 38px */

  color: #3e3092;
  position: absolute;
  left: 8%;
}

.prdeProdPricingCartPr {
  cursor: pointer;
  width: 120.21px;
  height: 120.21px;
  color: white;
  background: #ff3416;
  transform: rotate(45deg);
  font-family: "Ubuntu";
  display: flex;
  margin-left: 20px;
  margin-top: 5px;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 9%;
  left: 70%;
}
.prdeProdPricingRotateCartPr {
  display: flex;
  flex-direction: column;
  align-items: center;

  transform: rotate(-45deg);
}
.salePriceCartPr {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 54px;
  line-height: 111%;
  color: #ffffff;
  margin: 0;
}
.fullPriceCartPr {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 152.5%;
  /* or 27px */

  text-align: center;
  margin: 0;

  color: #ffffff;
}
.prodNewTwoSt {
  position: absolute;
  top: 28%;
  left: 42%;
  z-index: 2;
}
.prodNewOneSt {
  position: absolute;
  top: 28%;
  left: 14%;
  z-index: 1;
}
.blockSoonWrap {
  width: calc(100% - 40px);
  border: 20px solid #3e51c1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 50px;
}
.soonH5 {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 111%;
  /* or 40px */

  margin-top: 40px;
  margin-left: 73px;
  margin-bottom: 40px;
  color: #3e51c1;
}
.blockSoonProd {
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.blockSoonFirst {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 73px;
  width: 23%;
}
.soonPicProdSt {
  width: 190.57px;
  height: 224.23px;
}
.soonPicProdStDesc {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  align-self: flex-start;
  margin-left: 28px;
  height: 224.23px;
  width: 40%;
}
.soonAuthor {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 171%;
  /* or 31px */
  margin: 0;

  color: #3e51c1;
}
.soonAuthorName {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 171%;
  height: 30px;
  overflow: hidden;
  text-decoration: none;
  margin-top: 0;
  color: #3e51c1;
  cursor: pointer;
}
.rombWrap {
  cursor: pointer;
  width: 58.34px;
  height: 58.34px;
  color: white;
  background: #3e51c1;
  transform: rotate(45deg);
  font-family: "Ubuntu";
  display: flex;
  margin-left: 20px;
  margin-top: 5px;
  align-items: center;
  justify-content: center;
}
.rombWrapSecond {
  cursor: pointer;
  width: 58.34px;
  height: 58.34px;
  color: white;
  background: #3e51c1;
  transform: rotate(45deg);
  font-family: "Ubuntu";
  display: flex;
  margin-left: 20px;
  margin-top: 5px;
  align-items: center;
  justify-content: center;
}
.rombWrapPod {
  display: flex;
  flex-direction: column;
  align-items: center;

  transform: rotate(-45deg);
}
.pricePodRomb {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 111%;
  color: #ffffff;
  margin: 0;
}
.pricePodRombText {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 152.5%;
  /* or 24px */

  text-align: center;
  margin: 0;

  color: #ffffff;
}
.counterWrap {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 78%;
  margin-top: 10%;
}
.counterWrapSecond {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 70%;
  margin-top: 10%;
}
.boxCount {
  width: calc(32px - 2px);
  height: calc(34px - 2px);
  border: 1px solid #ff3416;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 117.5%;
  color: #3e3092;
  cursor: pointer;
}
.theCount {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 117.5%;
  color: #ff3416;
  margin: 0;
}
.childrenLike {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 130%;
  /* or 26px */

  color: #000000;
  width: 33%;
  margin-top: 0;
}
.greyWrapP {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 25%;
}
.greyP {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 182%;
  /* or 25px */
  width: 100%;

  color: rgba(0, 0, 0, 0.4);
}
.needReadWrap {
  width: calc(100% - 40px);
  height: auto;
  padding-bottom: 40px;
  border: 20px solid #3e51c1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.needReadH5 {
  margin-left: 73px;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 111%;
  /* or 40px */
  margin-bottom: 0;

  color: #3e51c1;
}
.needReadP {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 130%;
  /* or 26px */
  margin-left: 73px;
  margin-right: 40px;
  color: #000000;
}
.needProductWrap {
  margin-left: 73px;
  margin-right: 40px;
  margin-top: 50px;
  width: calc(100% - 113px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.prodOneWrap {
}
.blockSoonSecond {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  width: 30%;
}
.theBestForChildren {
  display: flex;
  width: calc(100% - 40px);
  height: auto;
  border: 20px solid #3e51c1;
  flex-direction: column;
  align-items: flex-start;
}
.theBestForChildrenH5 {
  margin-left: 73px;
  margin-right: 40px;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 111%;
  /* or 40px */
  margin-top: 40px;
  margin-bottom: 0;
  color: #3fac8c;
}
.theBestForChildrenP {
  margin-left: 73px;
  margin-right: 40px;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 130%;
  /* or 26px */

  color: #000000;
}
.bestForChildrenWrapEnd {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
  align-self: flex-end;
}
.priceOptH2 {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 111%;
  /* or 40px */

  color: #3e51c1;
}
.buttonOrderOpt {
  width: 266.37px;
  height: 56px;
  background: #3fac8c;
  border: none;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 117.5%;
  color: #ffffff;
  margin-left: 40px;
  margin-right: 28px;
}
.colorBlockWrap {
  padding: 70px;
  width: calc(100% - 140px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.electro {
  width: 45%;
  height: 102px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #3e51c1;

  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 117.5%;
  color: #ffffff;
}
.electroLink {
  text-decoration: none;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 117.5%;
  color: #ffffff;
}
.catolog {
  width: 45%;
  height: 102px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #f7cb25;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 117.5%;
  color: #3e3092;
}
.catalogLink {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 117.5%;
  color: #3e3092;
  text-decoration: none;
}
.delivery {
  width: 45%;
  height: 102px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #3e3092;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 117.5%;
  color: #ffffff;
}
.discount {
  width: 45%;
  height: 102px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #ff3416;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 117.5%;
  color: #ffffff;
}
@media only screen and (max-width: 599px) and (min-width: 300px) {
  .titleWrap {
    width: 100%;
    min-height: auto;
    background: #d6cfff;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .h3Title {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 111%;
    /* or 38px */
    margin-bottom: 0;
    text-align: center;
    width: 90%;
    color: #3e3092;
  }
  .birdPDesc {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 111%;
    /* or 20px */
    width: 100%;
    text-align: center;

    color: #3e3092;
  }
  .birdWrap {
    width: 50%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .salesBlockWrap {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 49px;
  }
  .blueBlockWrap {
    width: 100%;
    background: #3e3092;
    height: 510px;
    position: relative;
  }
  .yelowBlockWrap {
    width: 100%;
    background: #f7cb25;
    height: 454px;
    position: relative;
  }
  .saleForNewYear {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 111%;
    color: #ffffff;
    position: absolute;
    left: 8%;
  }
  .prdeProdPricingCart {
    cursor: pointer;
    width: 120.21px;
    height: 120.21px;
    color: white;
    background: #f7cb25;
    transform: rotate(45deg);
    font-family: "Ubuntu";
    display: flex;
    margin-left: 20px;
    margin-top: 5px;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 25%;
    left: 5%;
  }
  .saleNewOneSt {
    width: 154px;
    height: 136px;
    position: absolute;
    left: calc(70% - 68.5px);
    top: 5%;
  }
  .saleThreBlockSt {
    position: absolute;
    width: 137px;
    height: 178px;
    left: calc(75% - 68.5px);
    top: 25%;
  }
  .saleNewTwoSt {
    width: 137px;
    height: 178px;
    position: absolute;
    left: calc(65% - 68.5px);
    top: 44%;
  }
  .vidTwPcs {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 111%;
    /* or 27px */

    text-align: right;

    color: #ffffff;
    position: absolute;
    top: 68%;
    left: 5%;
  }
  .buttonJoin {
    border: none;
    width: 194px;
    height: 60px;
    background: #3fac8c;
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 117.5%;
    color: #ffffff;
    position: absolute;
    top: 80%;
    left: calc(50% - 97px);
  }
  .saleForProduction {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 111%;
    /* or 38px */

    color: #3e3092;
    position: absolute;
    left: 8%;
  }
  .prdeProdPricingCartPr {
    cursor: pointer;
    width: 120.21px;
    height: 120.21px;
    color: white;
    background: #ff3416;
    transform: rotate(45deg);
    font-family: "Ubuntu";
    display: flex;
    margin-left: 20px;
    margin-top: 5px;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 23%;
    left: 5%;
  }
  .buttonJoinPr {
    border: none;
    width: 194px;
    height: 60px;
    background: #3e3092;
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 117.5%;
    color: #ffffff;
    position: absolute;
    top: 80%;
    left: calc(50% - 97px);
  }
  .prodNewTwoSt {
    position: absolute;
    width: 116px;
    height: 154px;
    top: 25%;
    left: 65%;
    z-index: 2;
  }
  .prodNewOneSt {
    position: absolute;
    width: 116px;
    height: 154px;
    top: 28%;
    left: 50%;
    z-index: 1;
  }
  .vidTwPcsPr {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 111%;
    /* or 27px */

    text-align: right;

    color: #3e3092;
    position: absolute;
    top: 70%;
    left: 65%;
  }
  .theBestForChildrenH5 {
    margin-left: 12px;
    margin-right: 12px;
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 111%;
    /* or 40px */
    margin-top: 12px;
    margin-bottom: 0;
    color: #3fac8c;
  }
  .theBestForChildrenP {
    margin-left: 12px;
    margin-right: 12px;
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 130%;
    /* or 26px */

    color: #000000;
  }
  .needProductWrap {
    margin-left: 12px;
    margin-right: 12px;
    margin-top: 50px;
    width: calc(100% - 24px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .blockSoonSecond {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
    width: 90%;
  }
  .colorBlockWrap {
    padding: 0px;
    width: calc(100% - 0px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .electro {
    width: 100%;
    height: 102px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: #3e51c1;

    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 117.5%;
    color: #ffffff;
  }
  .catolog {
    width: 100%;
    height: 102px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: #f7cb25;
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 117.5%;
    color: #3e3092;
  }
  .delivery {
    width: 100%;
    height: 102px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: #3e3092;
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 117.5%;
    color: #ffffff;
  }
  .discount {
    width: 100%;
    height: 102px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: #ff3416;
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 117.5%;
    color: #ffffff;
  }
  .bestForChildrenWrapEnd {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
    align-self: flex-end;
  }
}
@media only screen and (max-width: 700px) and (min-width: 600px) {
  .titleWrap {
    width: 100%;
    min-height: auto;
    background: #d6cfff;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .h3Title {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 111%;
    /* or 38px */
    margin-bottom: 0;
    text-align: center;
    width: 90%;
    color: #3e3092;
  }
  .birdPDesc {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 111%;
    /* or 20px */
    width: 100%;
    text-align: center;

    color: #3e3092;
  }
  .birdWrap {
    width: 50%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .salesBlockWrap {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 49px;
  }
  .blueBlockWrap {
    width: 100%;
    background: #3e3092;
    height: 510px;
    position: relative;
  }
  .yelowBlockWrap {
    width: 100%;
    background: #f7cb25;
    height: 454px;
    position: relative;
  }
  .saleForNewYear {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 111%;
    color: #ffffff;
    position: absolute;
    left: 8%;
  }
  .prdeProdPricingCart {
    cursor: pointer;
    width: 120.21px;
    height: 120.21px;
    color: white;
    background: #f7cb25;
    transform: rotate(45deg);
    font-family: "Ubuntu";
    display: flex;
    margin-left: 20px;
    margin-top: 5px;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 25%;
    left: 5%;
  }
  .saleNewOneSt {
    width: 154px;
    height: 136px;
    position: absolute;
    left: calc(70% - 68.5px);
    top: 5%;
  }
  .saleThreBlockSt {
    position: absolute;
    width: 137px;
    height: 178px;
    left: calc(75% - 68.5px);
    top: 25%;
  }
  .saleNewTwoSt {
    width: 137px;
    height: 178px;
    position: absolute;
    left: calc(65% - 68.5px);
    top: 44%;
  }
  .vidTwPcs {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 111%;
    /* or 27px */

    text-align: right;

    color: #ffffff;
    position: absolute;
    top: 68%;
    left: 5%;
  }
  .buttonJoin {
    border: none;
    width: 194px;
    height: 60px;
    background: #3fac8c;
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 117.5%;
    color: #ffffff;
    position: absolute;
    top: 80%;
    left: calc(50% - 97px);
  }
  .saleForProduction {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 111%;
    /* or 38px */

    color: #3e3092;
    position: absolute;
    left: 8%;
  }
  .prdeProdPricingCartPr {
    cursor: pointer;
    width: 120.21px;
    height: 120.21px;
    color: white;
    background: #ff3416;
    transform: rotate(45deg);
    font-family: "Ubuntu";
    display: flex;
    margin-left: 20px;
    margin-top: 5px;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 23%;
    left: 5%;
  }
  .buttonJoinPr {
    border: none;
    width: 194px;
    height: 60px;
    background: #3e3092;
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 117.5%;
    color: #ffffff;
    position: absolute;
    top: 80%;
    left: calc(50% - 97px);
  }
  .prodNewTwoSt {
    position: absolute;
    width: 116px;
    height: 154px;
    top: 25%;
    left: 65%;
    z-index: 2;
  }
  .prodNewOneSt {
    position: absolute;
    width: 116px;
    height: 154px;
    top: 28%;
    left: 50%;
    z-index: 1;
  }
  .vidTwPcsPr {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 111%;
    /* or 27px */

    text-align: right;

    color: #3e3092;
    position: absolute;
    top: 70%;
    left: 65%;
  }
  .theBestForChildrenH5 {
    margin-left: 12px;
    margin-right: 12px;
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 111%;
    /* or 40px */
    margin-top: 12px;
    margin-bottom: 0;
    color: #3fac8c;
  }
  .theBestForChildrenP {
    margin-left: 12px;
    margin-right: 12px;
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 130%;
    /* or 26px */

    color: #000000;
  }
  .needProductWrap {
    margin-left: 12px;
    margin-right: 12px;
    margin-top: 50px;
    width: calc(100% - 24px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .blockSoonSecond {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
    width: 90%;
  }
  .colorBlockWrap {
    padding: 0px;
    width: calc(100% - 0px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .electro {
    width: 100%;
    height: 102px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: #3e51c1;

    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 117.5%;
    color: #ffffff;
  }
  .catolog {
    width: 100%;
    height: 102px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: #f7cb25;
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 117.5%;
    color: #3e3092;
  }
  .delivery {
    width: 100%;
    height: 102px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: #3e3092;
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 117.5%;
    color: #ffffff;
  }
  .discount {
    width: 100%;
    height: 102px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: #ff3416;
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 117.5%;
    color: #ffffff;
  }
  .bestForChildrenWrapEnd {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
    align-self: flex-end;
  }
}
@media only screen and (max-width: 1288px) and (min-width: 1024px) {
}
@media only screen and (max-width: 1024px) and (min-width: 701px) {
}
