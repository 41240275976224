html,
input,
button {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 16px;
}

.checkout-form {
  max-width: 325px;
  margin: 0 auto;
}

.checkout-form .container {
  border: 1px solid #ccc;
  padding: 0.85rem;
}
.paidButtonInCabinet{
  width: 300px;
}
.checkout-form .error-wrapper {
  padding: 0.15rem 0.15rem 1rem 0.15rem;
  display: none;
  color: #000000;
  color: darkred;
  font-size: 0.85rem;
  text-align:center;
}

.checkout-form .error-wrapper.show {
  display: block;
}

.checkout-form button.button {
  cursor: pointer;
}

.checkout-form input.input,
.checkout-form button.button {
  padding: .7rem;
  border: 1px solid #ccc;
  display: block;
  width: 100%;
  box-sizing: border-box;
  outline: none;
  text-align: center;
}

.checkout-form input.input:focus,
.checkout-form button.button:focus {
  box-shadow: inset 0px 0px 1px 1px lightgray;
  border-color: gray;
  position: relative;
  z-index: 2;
}

.checkout-form .input-wrapper {
  margin: 0 0 0.85rem;
}

.checkout-form .input-wrapper:last-child {
  margin-bottom: 0;
}

.checkout-form .input-label {
  font-size: 0.85rem;
  color: gray;
}

.checkout-form .input-label,
.checkout-form .input-field {
  box-sizing: border-box;
  padding: 0.15rem;
  float: left;
}

.checkout-form .input-wrapper:after {
  clear: both;
  content: '';
  display: block;
}

.w-1 {
  width: 100%;
}

.w-2 {
  width: 50%;
}

.w-3 {
  width: 33.33333%;
}

.w-3-2 {
  width: 66.66666%;
}

.w-4 {
  width: 25%;
}
@media only screen and (max-width: 479px) and (min-width: 300px) { 
  .paidButtonInCabinet{
    width: 100px;
  }
}
@media only screen and (max-width: 700px) and (min-width: 479px) { 
  .paidButtonInCabinet{
    width: 150px;
  }
}
@media only screen and (max-width: 1024px) and (min-width: 700px) {
  .paidButtonInCabinet{
    width: 200px;
  }
 }
